import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { getCupIdByName } from "../reducers/cups"
import { getTeamLeagueId } from "../functions/teams"
import getCssClassName from "../functions/getCssClassName"
import { cupYearHasWinner } from "../reducers/cups"
import { getCupHolderId } from "../reducers/cups"

function Cup() {
  const [yearIndex, setYearIndex] = useState(0)
  const cups = useSelector((state) => state.cups)
  const cupYears = useSelector((state) => state.cupYears)
  const teams = useSelector((state) => state.teams)
  const leagues = useSelector((state) => state.leagues)
  const seasons = useSelector((state) => state.seasons)
  let { cupName } = useParams()
  cupName = decodeURI(cupName)
  const cupId = getCupIdByName(cups, cupName)
  const cupIsFinal = cupYearHasWinner(cupYears[cupId][0])
  const getCupWinnerHistory = (cupYears) => {
    const winnersHistory = []
    cupYears.forEach((cupYear, i) => {
      if ((i === 0 && cupIsFinal) || i > 0) {
        winnersHistory[i] = {
          year: cupYear.year,
          winningTeamId: cupYear.teams.filter(
            (team) => team.knockedOut === false
          )[0].teamId,
        }
      }
    })
    return (
      <table className="table--">
        <caption>Past Winners</caption>
        <thead>
          <tr>
            <th>Year</th>
            <th>Team</th>
          </tr>
        </thead>
        <tbody>
          {winnersHistory.map((cupYear, i) => {
            if (i === 0 && !cupIsFinal) {
              return null
            }
            return (
              <tr key={i}>
                <td>{cupYear.year}</td>
                <td
                  className={getCssClassName(
                    `${teams[cupYear.winningTeamId].shortName}-${
                      teams[cupYear.winningTeamId].logo
                    }`
                  )}
                >
                  {teams[cupYear.winningTeamId].shortName}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
      <nav className="previous-next">
        {yearIndex > 0 && (
          <button
            className="previous"
            onClick={() => setYearIndex(yearIndex - 1)}
          >
            &larr; {cupYears[cupId][yearIndex - 1].year}
          </button>
        )}
        {cupYears[cupId][yearIndex + 1] !== undefined && (
          <button className="next" onClick={() => setYearIndex(yearIndex + 1)}>
            &rarr; {cupYears[cupId][yearIndex + 1].year}
          </button>
        )}
      </nav>
      <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">{`${cupYears[cupId][yearIndex].year} ${cupName}`}</h1>
      <ul className="bosh">
        <li>
          <span>Leagues eligible</span>
          <span>
            {leagues
              .filter((league) => cups[cupId].tiers.includes(league.tier))
              .map((league, i) => {
                return i === 0 ? league.name : `, ${league.name}`
              })}
          </span>
        </li>
        <li>
          <span>Prize money</span>
          <span>£{cups[cupId].topPrizeMoney.toLocaleString()}</span>
        </li>
      </ul>
      <div className="card c">
        <table className="table--">
          <thead>
            <tr>
              <th>Team</th>
              <th colSpan="2">Division</th>
            </tr>
          </thead>
          <tbody>
            {cupYears[cupId][yearIndex].teams.map((team, j) => (
              <tr key={j}>
                <td>
                  <Link
                    to={`/team/${getCssClassName(
                      teams[team.teamId].shortName
                    )}`}
                  >
                    {teams[team.teamId].shortName}
                  </Link>
                </td>
                <td>{leagues[getTeamLeagueId(seasons, team.teamId)].name}</td>
                <td>{team.knockedOut && <span className="red">✘</span>}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {getCupHolderId(cupYears, cupId) > -1 &&
          getCupWinnerHistory(cupYears[cupId])}
      </div>
    </div>
  )
}

export default Cup
