import { getTeamLeagueId } from "../functions/teams"
import { getNextFixtureForTeam, getNextCompetitionFixtureDetails } from "../functions/fixtures"
import { aiBids, processBids } from "../functions/endPeriod"
import { newBidResponseNews } from "../functions/market"
import { generateAllLeagueFixtures, getDateWithAddedDays } from "../functions/generateLeagueFixtures"
import generateAllCupFixtures from "../functions/generateCupFixtures"
import { generateCupYears, generateSeasonTeams, generateLeagueMilestoneNews, generateLeaguePrizeMoney, getExpiredLoans } from "../functions/endSeason"
import playMatches from "../functions/match/playMatches"
import releasePlayer from "../localFunctions/releasePlayer"
import { progressToDate, addCupFixtures, matchPlayed, addNews as addNewsLocal, bidAccepted, bidRejected, bidForPlayer, updatePlayerHistory, adjustBankBalance,
      nextSeasons, nextYear, addLeagueFixtures, addTeamToCupYear, addMilestone, endPlayerLoan, relegateTeam, promoteTeam, playersTraining, unreadyTeams  } from "../actions"
import store from '../store';
import workerSend from "../appWorker"
import moment from "moment"

const dispatch = (action) => store.dispatch(action)

export const getNextUserFixture = () => {
  const { user, seasons, fixtures } = store.getState()
  return getNextFixtureForTeam(
    fixtures.leagues[getTeamLeagueId(seasons, user.teamId)],
    fixtures.cups,
    user.teamId
  )
}

const isLeagueStarted = () => {
  const { user, fixtures } = store.getState()
  const { nextFixtureKickOff, nextFixtureCompetitionType } = getNextCompetitionFixtureDetails(fixtures, 'league')
  return user.teamId >= 0 &&
      (fixtures.leagues[0].length < 38
      || (nextFixtureKickOff && nextFixtureKickOff === user.currentTime // TODO if progressing beyond day resolution, just check we're on the same day.
         && nextFixtureCompetitionType === 'league'))
}

export const isPreSeason = () => {
  const { user, seasons, fixtures, market } = store.getState()
  return fixtures.leagues[getTeamLeagueId(seasons, user.teamId)].length === 38 &&
  market.isOpen &&
  !isLeagueStarted()
}

const nextTurn = async (fastForward) => {
  const { user } = store.getState()
  if (user.isLocalGame) {
    return nextDay(fastForward)
  } else {
    workerSend(fastForward ? "fastForward" : "ready");
  }
}

export const isUserMatchDay = () => {
  const { user } = store.getState()
  const nextFixtureForTeam = getNextUserFixture()
  return (nextFixtureForTeam && nextFixtureForTeam.kickOff < getDateWithAddedDays(user.currentTime, 1).toISOString())
}

const nextDay = fastForward => {
  const { fixtures, teams, matches, cups, cupYears } = store.getState()
  const { nextFixtureKickOff } = getNextCompetitionFixtureDetails(fixtures) || {}
  if (!nextFixtureKickOff) {
    finishSeason()
  } else {
    if (isPreSeason()) {
      endPeriod()
      if (fastForward) {
       if (nextFixtureKickOff > store.getState().user.currentTime) {
         nextDay(fastForward)
       }
      }
    } else {
      if (nextFixtureKickOff > store.getState().user.currentTime) {
        endPeriod()
      } else if (teamsReady(teams)) {
        runMatches()
        dispatch(unreadyTeams())
        endPeriod()
        dispatch(addCupFixtures(generateAllCupFixtures(cups, teams, matches, cupYears, fixtures.cups)))
      }
    }
  }
}

const teamsReady = (teams) => !teams.some(t => t.userId && !t.ready)

const finishSeason = () => {
  const { user, teams, players, leagues, seasons, cups } = store.getState()
  const nextCupYear = parseInt(moment(user.currentTime).format('YYYY'))
    const teamCupYears = generateCupYears(leagues, seasons, cups, nextCupYear)
    teamCupYears.forEach(teamCupYear => dispatch(addTeamToCupYear(teamCupYear.cupId, teamCupYear.teamId, teamCupYear.year)))

    const { newTeams, promotions, relegations, milestones } = generateSeasonTeams(
      leagues,
      seasons,
      teams,
      players,
      cups,
    )
    milestones.forEach(milestone => dispatch(addMilestone({...milestone})))
    promotions.forEach(teamId => dispatch(promoteTeam(teamId)))
    relegations.forEach(teamId => dispatch(relegateTeam(teamId)))

    const news = generateLeagueMilestoneNews([user], seasons, teams, user.currentTime, players)
    if (user.id in news) {
      dispatch(addNewsLocal(news[user.id]))
    }

    // Release the players whose contract just expired
    players.filter(p => p.teamId > -1 && p.contractExpiry < 1).forEach(player =>
      releasePlayer(user, player, teams[player.teamId], true)
    )

    const newMonies = generateLeaguePrizeMoney(leagues, seasons)
    newMonies.forEach((newMoney, teamId) => {
      dispatch(adjustBankBalance(teamId, newMoney))
    });

    getExpiredLoans(players).forEach(player => {
      dispatch(endPlayerLoan(player.id, player.borrowingTeamId, player.teamId))
    })

    // Generate next seasons
    dispatch(nextSeasons(newTeams))
    // Annual actions
    dispatch(nextYear((seasons.length / leagues.length) + 1, cups, nextCupYear))
    // Generate Fixtures
    dispatch(addLeagueFixtures(generateAllLeagueFixtures(newTeams, leagues, store.getState().seasons, teams)))

    const julyThisYear = new Date(user.currentTime)
    julyThisYear.setMonth(6)
    julyThisYear.setDate(1)
    dispatch(progressToDate(julyThisYear.toISOString()))
}

const endPeriod = () => {
  const { user, teams, players, seasons, market } = store.getState()

  if (market.isOpen) {
    const { news, newBids } = aiBids(seasons.length, teams, players, user.currentTime, [user])
    newBids.forEach(bid => dispatch(bidForPlayer(bid)))

    const { bidsToAccept, bidsToReject } = processBids(market.bids, teams, players, seasons.length, user.currentTime)
    bidsToAccept.forEach(bid => {
      dispatch(bidAccepted(bid))
      newBidResponseNews(news, teams, [user], teams[bid.sellingTeamId], user.currentTime, teams[bid.buyingTeamId], players[bid.playerId], bid.fee, 'accept')
    })
    bidsToReject.forEach(bid => {
      dispatch(bidRejected(bid))
      newBidResponseNews(news, teams, [user], teams[bid.sellingTeamId], user.currentTime, teams[bid.buyingTeamId], players[bid.playerId], bid.fee, 'reject')
    })

    if (user.id in news) {
      dispatch(addNewsLocal(news[user.id]))
    }
  }

  dispatch(playersTraining(teams))

  dispatch(progressToDate(getDateWithAddedDays(user.currentTime, 1).toISOString()))
}


const runMatches = () => {
  let { user, teams, players, playersHistory, fixtures, board } = store.getState()
  const { nextFixtureCompetitionId, nextFixtureCompetitionType } = getNextCompetitionFixtureDetails(fixtures) || {}
  const { results, playerHistories, news } =
  playMatches(
    playersHistory,
    teams,
    players,
    nextFixtureCompetitionType === 'league'
      ? fixtures.leagues[nextFixtureCompetitionId][0]
      : fixtures.cups[nextFixtureCompetitionId],
    board
  )

  results.forEach(result => {
    dispatch(matchPlayed(result))
  });
  playerHistories.forEach((playerHistory, playerId) => {
    dispatch(updatePlayerHistory(playerId, playerHistory))
  });
  if (news[user.id]) {
    dispatch(addNewsLocal(news[user.id]))
  }

  fixtures = store.getState().fixtures
  const { nextFixtureKickOff } = getNextCompetitionFixtureDetails(fixtures) || {}
  if (nextFixtureKickOff < getDateWithAddedDays(user.currentTime, 1).toISOString()) {
    runMatches()
  }
}


export default nextTurn
