const calculateConfidence = (confidence) => {
  if (confidence <= 20) {
    return "Terrible"
  } else if (confidence <= 40) {
    return "Okay"
  } else if (confidence <= 60) {
    return "Good"
  } else if (confidence <= 80) {
    return "Very good"
  } else if (confidence > 81) {
    return "Untouchable"
  }
}
export default calculateConfidence
