
import { useSelector, useDispatch } from "react-redux"
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd"

import workerSend from "../../appWorker"
import {
    movePlayerPositionLocal,
  } from "../../actions"

import abbreviatePosition from "../../functions/abbreviatePosition"

import { getPlayerTeamLatestSeasonHistory } from "../../reducers/playersHistory"

export default function Lineup({setViewPlayer}) {
  const dispatch = useDispatch()
  const players = useSelector((state) => state.players)
  const playersHistory = useSelector((state) => state.playersHistory)
  const user = useSelector((state) => state.user)
  const team = useSelector((state) => state.teams[user.teamId])
  const formation = team.formation

  const movePlayerPosition = (currentIndex, newIndex) => {
    if (user.isLocalGame) {
      dispatch(movePlayerPositionLocal(team.id, currentIndex, newIndex))
    } else {
      workerSend("movePlayerPosition", { currentIndex, newIndex })
    }
  }

  // to do it better: https://egghead.io/lessons/react-customise-the-appearance-of-an-app-during-a-drag-using-react-beautiful-dnd-snapshot-values
  const onDragStart = result => {
   
  }
  const onDragUpdate = result => {
   
    
  }
  const onDragEnd = ({ source, destination }) => { 
     // droppableId in source and destination
     // if needed for having more than one thing in the context
     // also, draggableId if needed from result (same level as source and destination)
    if (!destination ||
        (destination.index === source.index
        && destination.droppableId === source.droppableId)) {
      return null
    }
    
    movePlayerPosition(source.index, destination.index)
  }

  return (
      <DragDropContext
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
          <table className={"table--lineup-" + formation + " card"}>
            <thead>
              <tr>
                <th>Shirt #</th>
                <th></th>
                <th>
                  <abbr title="Position">POS</abbr>
                </th>
                <th>Name</th>
                <th>
                  <abbr title="Appearances">APP</abbr>
                </th>
                <th>
                  <abbr title="Goals">GLS</abbr>
                </th>
                <th>Wage</th>
                <th>
                  <abbr title="Physical, Mental, Technical, & Overall Rating">
                    RAT
                  </abbr>
                </th>
                <th>Condition</th>
                <th>Form</th>
              </tr>
            </thead>
            <Droppable droppableId={"squadOrder"}>
            {(provided) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {team.squadOrder.map((playerId, i) => {
                  let player = players[playerId]
                  let playerHistory = getPlayerTeamLatestSeasonHistory(
                    playersHistory,
                    playerId,
                    team.id
                  )
                  let appearances = !playerHistory ? 0 : playerHistory.appearances
                  let goals = appearances > 0 ? playerHistory.goals : 0
                  let { physical, mental, technical } = player.rating
                  let ratingOutOf100 = Math.floor((technical + mental + physical) / 3)
                  return (
                    <Draggable draggableId={'draggablePlayerRowId'+i} index={i} key={'draggablePlayerRow'+i}>
                      {provided => (
                        <tr
                          key={playerId}
                          data-rating={ratingOutOf100}
                          data-position={player.position.toLowerCase()}
                          data-form={Math.round(player.form / 10) * 10}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <td>{player.borrowingTeamId === null ? player.shirtNumber : player.loanShirtNumber}</td>
                          <td className="c">
                            {player.loanListed && <i className="icon--loan-listed"></i>}
                            {player.transferListed && (
                              <i className="icon--transfer-listed"></i>
                            )}
                          </td>
                          <td className={player.position.toLowerCase()}>
                            {abbreviatePosition(player.position)}
                          </td>
                          <td
                            className="onClick"
                            onClick={() => setViewPlayer(player)}
                          >{`${player.firstName} ${player.lastName}`}</td>
                          <td>{appearances}</td>
                          <td>{goals}</td>
                          <td className="accounting">{player.wage.toLocaleString()}</td>
                          <td className="highlight">
                            <div
                              className="stars"
                              style={{ "--rating": ratingOutOf100 / 20 }}
                            ></div>
                          </td>
                          <td>{player.condition}</td>
                          <td>{player.form}</td>
                        </tr>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                </tbody>
              )}
            </Droppable>
        </table>
        </DragDropContext>
  )
}