import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import MatchResultDetail from "./MatchResultDetail"
import { getMatch } from "../reducers/matches"
import getCssClassName from "../functions/getCssClassName"
import winLoseDraw from "../functions/winLoseDraw"
import moment from "moment"
import Button from "./Button"
import getTeamCircle from "../functions/getTeamCircle"
function Results() {
  const user = useSelector((state) => state.user)
  const matches = useSelector((state) => state.matches)
  const teams = useSelector((state) => state.teams)
  const players = useSelector((state) => state.players)
  const leagues = useSelector((state) => state.leagues)
  const cups = useSelector((state) => state.cups)
  const team = useSelector((state) => state.teams[user.teamId])
  const [isFiltered, setFiltered] = useState(true)
  let { id } = useParams()
  const matchDetail = id === undefined ? false : getMatch(matches, id) || false

  const filteredMatches = !isFiltered
    ? matches
    : matches.filter((match) => {
        return match.homeId === team.id || match.awayId === team.id
      })

  const filterHeader = (
    <header className="flex justify-center gap-4 pb-8">
      <Button
        onClickFunction={() => setFiltered(true)}
        selected={isFiltered && "selected"}
        label={team.name + " Results"}
      ></Button>
      <Button
        onClickFunction={() => setFiltered(false)}
        selected={!isFiltered && "selected"}
        label="All Results"
      ></Button>
    </header>
  )

  const MatchResultsList = (
    <React.Fragment>
      {filterHeader}
      <div className="results-list">
        <div className="card flex flex-col">
          {filteredMatches.map((match, i) => {
            const competition =
              match.leagueId !== undefined
                ? leagues[match.leagueId]
                : cups[match.cupId]
            let homeTeam = teams[match.homeId]
            let awayTeam = teams[match.awayId]
            return (
              <div
                key={i}
                className="flex flex-col justify-between p-4 gap-4 m:gap-0"
              >
                <header className="flex justify-between">
                  <span className="text-xs">
                    {moment(match.kickOff).format("MMM D, YYYY")}
                  </span>
                  <span className="text-xs">{competition.name}</span>
                </header>
                <div className="grid justify-center grid-cols-[minmax(50px,_1fr)_100px_minmax(50px,_1fr)]">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2 items-end">
                      <div className="flex">
                        {getTeamCircle(teams?.[homeTeam.id]?.colors[3]?.other)}
                        {getTeamCircle(teams?.[homeTeam.id]?.colors[2]?.away)}
                        {getTeamCircle(
                          teams?.[homeTeam.id]?.colors[1]?.secondary
                        )}
                        {getTeamCircle(
                          teams?.[homeTeam.id]?.colors[0]?.primary
                        )}
                      </div>
                      <span className="text-sm">{homeTeam.name}</span>
                    </div>
                  </div>
                  <div className="center">
                    <p className="mb-2">
                      <Link
                        className={
                          "p-1 rounded-md " +
                          (isFiltered
                            ? winLoseDrawToHSL(
                                team.id,
                                match.homeId,
                                match.homeScore,
                                match.awayScore
                              )
                            : "bg-violet-100")
                        }
                        to={"/matchdetail/" + match.id}
                      >
                        {match.homeScore} - {match.awayScore}
                      </Link>
                    </p>
                    <p className="font-mono text-xs">
                      {match.attendance.toLocaleString()}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex">
                      {getTeamCircle(teams?.[awayTeam.id]?.colors[3]?.other)}
                      {getTeamCircle(teams?.[awayTeam.id]?.colors[2]?.away)}
                      {getTeamCircle(
                        teams?.[awayTeam.id]?.colors[1]?.secondary
                      )}
                      {getTeamCircle(teams?.[awayTeam.id]?.colors[0]?.primary)}
                    </div>
                    <span className="text-sm">{awayTeam.name}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )

  const MatchResultsDetail = (matchDetail) => (
    <React.Fragment>
      <MatchResultDetail
        match={matchDetail}
        players={players}
        homeTeam={teams[matchDetail.homeId]}
        awayTeam={teams[matchDetail.awayId]}
      ></MatchResultDetail>
      <footer className="action">
        <Link to="/results" className="btn first">
          Back to Results List
        </Link>
      </footer>
    </React.Fragment>
  )

  const MatchResults = (
    <React.Fragment>
      {matchDetail ? MatchResultsDetail(matchDetail) : MatchResultsList}
    </React.Fragment>
  )

  return <div className="p-1 s:p-2 m:p-3 l:p-4 grow">{MatchResults}</div>
}

export default Results

const winLoseDrawToHSL = (id, homeId, homeScore, awayScore) => {
  const jakko = winLoseDraw(id, homeId, homeScore, awayScore)
  if (jakko === "win") return "bg-green-500 !text-white"
  if (jakko === "lose") return "bg-red-500 !text-white"
  return "bg-gray-500 !text-white"
}
