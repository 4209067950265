import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { addNews as addNewsLocal, addPlayerToLongList, removePlayerFromLongList, demotePlayerToYouthSquad, promotePlayerToSeniorSquad } from "../actions"
import { makeNews } from "../functions/addNews"
import { calculateContractValue } from "../functions/calculateContractValue"
import getCssClassName from "../functions/getCssClassName"
import { goalPercentage } from "../functions/getGoalPercentage"
import Jersey from "./jersey"
import { pluralYear } from "../functions/pluralYear"
import workerSend from "../appWorker"
import Stars from "./Stars"
import Button from "./Button";

function PlayerDetail(props) {
  const dispatch = useDispatch()
  const players = useSelector(state => state.players)
  const player = players.find(player => props.player.id === player.id)
  const team = useSelector(state => state.teams[player.teamId])
  const playerHasTeam = team !== undefined ? true : false
  const leagues = useSelector(state => state.leagues)
  const cups = useSelector(state => state.cups)
  const teams = useSelector(state => state.teams)
  const user = useSelector(state => state.user)
  const usersPlayer = props.player.teamId === user.teamId
  const playerHistory = useSelector(state => state.playersHistory)
  const playerHistoryTable = (player, playerHistory) => {
    //let totals = {appearances: 0, goals: 0}
    return playerHistory[player.id].map((competition, i) => {
      const competitions = competition.competitionType === 'league' ? leagues : cups
      return (
        <tr key={i}>
          <td>{teams[competition.teamId].name}</td>
          <td>{competition.competitionYear}</td>
          <td>{competitions[competition.competitionId].name}</td>
          <td>{competition.appearances}</td>
          <td>{competition.goals}</td>
        </tr>
      )
    })
  }

  const removeFromLongList = (id) => {
    if (user.isLocalGame) {
      dispatch(removePlayerFromLongList(player.id))
    } else {
      workerSend("removeFromLongList", { playerId: player.id })
    }
  }

  const addToLongList = (id) => {
    if (user.isLocalGame) {
      dispatch(addPlayerToLongList(player.id))
    } else {
      workerSend("addToLongList", { playerId: player.id })
    }
  }

  const promoteToSenior = () => {
    if (user.isLocalGame) {
      dispatch(promotePlayerToSeniorSquad(user.teamId, player.id))
      dispatch(addNewsLocal([
        makeNews(user.currentTime,
          team.id,
          `${player.firstName} ${player.lastName} Promoted to Senior Squad`,
          "team-selection",
          "players")])
      )
    }
  }

  const demoteToYouth = () => {
    if (user.isLocalGame) {
      dispatch(demotePlayerToYouthSquad(user.teamId, player.id))
      dispatch(addNewsLocal([
        makeNews(user.currentTime,
          team.id,
          `${player.firstName} ${player.lastName} Demoted to Youth Squad`,
          "team-selection",
          "players")])
      )
    }
  }
  return (
    <React.Fragment>
      <div className="card-and-detail">
        <aside className="center">
          {playerHasTeam && <Jersey color={getCssClassName(team.shortName)} />}
        </aside>
        <dl className="player-overview">
          <dt>Name</dt><dd>{`${player.firstName} ${player.lastName}`}</dd>
          {player.teamId > -1 && <><dt>Team</dt><dd>{`${teams[player.teamId].shortName}`}</dd></>}
          <dt>Age</dt><dd>{player.age}</dd>
          <dt>Position</dt><dd>{player.position}</dd>
          <dt>Wage</dt><dd>£{player.wage.toLocaleString()}/week for {pluralYear(player.contractExpiry)} (£{calculateContractValue(player.wage, player.contractExpiry)} total)</dd>
          <dt>Appearances</dt><dd>{player.appearances}</dd>
          {player.goals > 0 && <React.Fragment><dt>Goals</dt><dd>{player.goals} in {player.appearances} games ({goalPercentage(player.goals, player.appearances)}%)</dd></React.Fragment>}
          <dt>Transfer status</dt><dd>{player.transferListed || player.teamId === -1 ? <span className="label__transfer-available">Available</span> : "Unavailable"}</dd>
          <dt>Loan status</dt><dd>{player.loanListed ? <span className="label__transfer-available">Available</span> : "Unavailable"}</dd>
        </dl>
        <section>
          <Stars player={player} />
        </section>
      </div>
      {usersPlayer && (
        <dl className="my-4">
          <dt>Squad</dt>
          <dd>
            <span className="mr-4">{player.isSenior ? `Senior` : `Youth`}</span>
            {
              player.isSenior
                ? <Button onClickFunction={() => demoteToYouth()} label="Move to Youth Squad" />
                : <Button selected="selected" onClickFunction={() => promoteToSenior()} label="Move to Senior" />
            }
          </dd>
        </dl>
      )}
      <section className="my-4">
        {
          !usersPlayer && (
            user.longList.find(id => id === player.id)
              ? <Button selected="selected" onClickFunction={() => removeFromLongList(player.id)} label="Remove from Long List" />
              : <Button onClickFunction={() => addToLongList(player.id)} label="Add to Long List" />
          )
        }
      </section>
      <table className="table--">
        <thead>
          <tr>
            <th>Club</th>
            <th>Season</th>
            <th>Competition</th>
            <th>Appearances</th>
            <th>Goals</th>
          </tr>
        </thead>
        <tbody>
          {playerHistoryTable(player, playerHistory)}
        </tbody>
      </table>
      <hr className="my-4" />
    </React.Fragment>
  )
}

export default PlayerDetail
