const host = window.location.hostname
const isDev = host === 'localhost'

const httpProtocol = isDev ? 'http://' : 'https://'
const url = httpProtocol + host

const apiHost = isDev
    ? "localhost:8080"
    : host === "d2m1aykxogntzv.cloudfront.net/" ? "d3fdfc8dfsiod8.cloudfront.net" : "api.unbeaten.run"

const apiUrl = httpProtocol + apiHost
const wsUrl = isDev ? `ws://${apiHost}` : `wss://${apiHost}`

export {
  url,
  apiUrl,
  wsUrl,
  isDev
}
