import React, { useState } from "react"
import { useSelector } from "react-redux"
import marketValue from "../functions/marketValue"
import abbreviatePosition from "../functions/abbreviatePosition"
import Modal from "react-modal"
import PlayerDetail from "./PlayerDetail"
import BidForPlayer from "./BidForPlayer"
import sortPlayers from "../functions/sortPlayers"
import addSortedClass from "../functions/addSortedClass"
import Button from "./Button"
import average from "../functions/average"

import getCssClassName from "../functions/getCssClassName"
import Stars from "./Stars"

function starstoInt(stars = "★") {
  switch (stars) {
    case "★★★★":
      return 4
    case "★★★":
      return 3
    case "★★":
      return 2
    default:
      return 1
  }
}
const intToPercentage = (no) => no * 20

function TransferList() {
  const user = useSelector((state) => state.user)
  const teams = useSelector((state) => state.teams)
  let players = useSelector((state) => state.players)
  // Players not belonging to the user or free agents
  players = players.filter(
    (player) => player.teamId !== user.teamId && player.teamId > -1
  )
  const [filteredPosition, setFilteredPosition] = useState(false)
  const [filteredTransferType, setFilteredTransferType] = useState("Transfer")
  const [filterByStars, setFilterByStars] = useState("★")
  let [toggle, setToggle] = useState(false) // use this to toggle the ascending/descending sortOrder, should the user have pressed sortBy Name twice
  let [sortBy, setSortBy] = useState("age")
  const isSelected = (position) =>
    filteredPosition === position || filteredTransferType === position
      ? "selected"
      : undefined
  const checkForFilters = () => {
    if (filteredPosition !== false)
      players = players.filter((player) => player.position === filteredPosition)
    if (filteredTransferType === "Transfer")
      players = players.filter((player) => player.transferListed === true)
    if (filteredTransferType === "Loan")
      players = players.filter((player) => player.loanListed === true)

    players = players.filter(
      (player) =>
        average([
          player.rating.mental,
          player.rating.technical,
          player.rating.physical,
        ]) >= intToPercentage(starstoInt(filterByStars))
    )

    if (players.length > 0) {
      players = sortPlayers(players, sortBy, toggle)
      return players.map((player, i) => (
        <tr key={i}>
          <td className="c">
            {player.loanListed && <i className="icon--loan-listed"></i>}
            {player.transferListed && <i className="icon--transfer-listed"></i>}
          </td>
          <td>{abbreviatePosition(player.position)}</td>
          <td>{player.age}</td>
          <td>
            <Stars player={player} />
          </td>
          <td className={getCssClassName(teams[player.teamId].shortName)}>
            {teams[player.teamId].shortName}
          </td>
          <td
            className="onClick"
            onClick={() => setPlayer(player)}
          >{`${player.firstName} ${player.lastName}`}</td>
          <td className="accounting">{player.wage.toLocaleString()}</td>
          <td className="accounting">
            {marketValue(player, player.contractExpiry).toLocaleString()}
          </td>
        </tr>
      ))
    } else {
      return (
        <tr>
          <td className="c" colSpan="8">
            No players found
          </td>
        </tr>
      )
    }
  }

  let [player, setPlayer] = useState(false)

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
      {player && (
        <Modal ariaHideApp={false} isOpen={player.id >= 0}>
          {<PlayerDetail player={player} />}
          {<BidForPlayer player={player} setPlayer={setPlayer} />}
          <hr className="my-4" />
          <Button
            onClickFunction={() => setPlayer(false)}
            className=""
            label="Close"
          />
        </Modal>
      )}
      <header className="center">
        <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center text-2xl font-bold font-sans tracking-widest">
          Transfer List
        </h1>
        <ul className="flex items-center justify-center gap-4 mb-4">
          <li>
            <Button
              label="Transfer"
              selected={isSelected("Transfer")}
              onClickFunction={() => setFilteredTransferType("Transfer")}
            />
          </li>
          <li>
            <Button
              label="Loan"
              selected={isSelected("Loan")}
              onClickFunction={() => setFilteredTransferType("Loan")}
            />
          </li>
        </ul>
        <nav className="flex flex-col s:flex-row justify-center gap-4">
          <ul className="flex items-center justify-center gap-4 mb-4">
            <li>
              Filter <span className="hidden s:inline">by position</span>
            </li>
            <li>
              <Button
                label="GK"
                selected={isSelected("Goalkeeper")}
                onClickFunction={() => setFilteredPosition("Goalkeeper")}
              />
            </li>
            <li>
              <Button
                label="DF"
                selected={isSelected("Defender")}
                onClickFunction={() => setFilteredPosition("Defender")}
              />
            </li>
            <li>
              <Button
                label="MF"
                selected={isSelected("Midfield")}
                onClickFunction={() => setFilteredPosition("Midfield")}
              />
            </li>
            <li>
              <Button
                label="FW"
                selected={isSelected("Forward")}
                onClickFunction={() => setFilteredPosition("Forward")}
              />
            </li>
          </ul>
          <ul className="flex">
            <label htmlFor="min-stars">
              <span className="inline s:hidden">Min.</span>
              <span className="hidden s:inline">Minimum ability</span>
            </label>
            <select
              value={filterByStars}
              id="min-stars"
              onChange={(e) => {
                setFilterByStars(e.target.value)
              }}
            >
              <option>★★★★</option>
              <option>★★★</option>
              <option>★★</option>
              <option>★</option>
            </select>
          </ul>
        </nav>
      </header>
      <table className="table--ghost card">
        <thead>
          <tr>
            <th></th>
            <th>POS</th>
            <th
              className={addSortedClass(sortBy, "age")}
              onClick={() => {
                if (sortBy === "age") {
                  setToggle(!toggle)
                }
                setSortBy("age")
              }}
            >
              Age
            </th>
            <th>Rating</th>
            <th>Club</th>
            <th
              className={addSortedClass(sortBy, "lastName")}
              onClick={() => {
                if (sortBy === "lastName") {
                  setToggle(!toggle)
                }
                setSortBy("lastName")
              }}
            >
              Name
            </th>
            <th
              className={addSortedClass(sortBy, "wage")}
              onClick={() => {
                if (sortBy === "wage") {
                  setToggle(!toggle)
                }
                setSortBy("wage")
              }}
            >
              Wage
            </th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{checkForFilters()}</tbody>
      </table>
    </div>
  )
}

export default TransferList
