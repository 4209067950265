import React, { useState } from "react"
import { Link } from "react-router-dom"
import { getNextFixtureForTeam } from "../functions/fixtures"
import { getLastMatchForTeam } from "../reducers/matches"
import { getTeamLeagueId } from "../functions/teams"
import { contractAlert } from "./NavHorizontal"
import getCssClassName from "../functions/getCssClassName"
import getTopScorer from "../functions/getTopScorer"
import calculateConfidence from "../functions/calculateConfidence"
import latestMatches from "../functions/latestMatches"
import whichTeam from "../functions/whichTeam"
import limitString from "../functions/limitString"
import winLoseDraw from "../functions/winLoseDraw"
import Button from "./Button"
import { archiveNewsItem, archiveAllNewsItems } from "../actions"
import workerSend from "../appWorker"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"

function Overview() {
  const user = useSelector((state) => state.user)
  const thisTeamId = user.teamId
  const teams = useSelector((state) => state.teams)
  const players = useSelector((state) => state.players)
  const board = useSelector((state) => state.board[thisTeamId])
  const thisTeam = teams[thisTeamId]
  const seasons = useSelector((state) => state.seasons)
  const fixtures = useSelector((state) => state.fixtures)
  const matchGroups = useSelector((state) => state.matches)
  const nextMatch = getNextFixtureForTeam(
    fixtures.leagues[getTeamLeagueId(seasons, thisTeam.id)],
    fixtures.cups,
    thisTeamId
  ) || { homeId: 0, awayId: 0 }
  const nextOpponentId =
    nextMatch.homeId === thisTeamId ? nextMatch.awayId : nextMatch.homeId

  const dispatch = useDispatch()
  const news = user.news
  const concerns = ["match", "transfer", "milestone"]
  const newsByType = []
  concerns.forEach((concern) => {
    newsByType[concern] = news.filter((item) => item.concern === concern)
  })
  const [newsType, setNewsType] = useState(
    Object.keys(newsByType)
      .filter((concern) => newsByType[concern].length)
      .shift() || concerns[0]
  )
  const isArchived = (item) => {
    return item.archived === false ? " font-bold" : ""
  }

  const archiveAllNews = () => {
    if (user.isLocalGame) {
      dispatch(archiveAllNewsItems())
    } else {
      workerSend("archiveAllNews")
    }
  }

  const getNextMatch = () => {
    return (
      <div className="card pt-1 pb-4 px-2 animate-fade area--match">
        <h4 className="text-xs uppercase text-slate-400 mb-4">Next Match</h4>
        <div className="flex items-center flex-col gap-2">
          <h3
            className={getCssClassName(
              `${teams[nextOpponentId].shortName}-${teams[nextOpponentId].logo}`
            )}
          >
            <Link
              to={
                "team/" + getCssClassName(`${teams[nextOpponentId].shortName}`)
              }
            >
              {teams[nextOpponentId].shortName}
            </Link>
          </h3>
          <Link
            className="uppercase text-xs bg-violet-200 hover:bg-violet-900 hover:text-violet-100 transition-all"
            to="/team-selection"
          >
            Select Team
          </Link>
        </div>
      </div>
    )
  }
  const lastFiveMatches = () => {
    return (
      <div className="card pt-1 pb-4 px-2 animate-fade area--match">
        <h4 className="text-xs uppercase text-slate-400 mb-4">
          Last Five Matches
        </h4>
        <div className="flex items-center flex-col gap-2">
          <div className="flex w-full justify-around items-end">
            {latestMatches(matchGroups, thisTeamId, 5).map((match, i) => {
              let opponentId = whichTeam(
                match.homeId,
                match.awayId,
                thisTeamId,
                true
              )
              return (
                <div className="flex flex-col items-center" key={i}>
                  <p
                    className={getCssClassName(
                      `${teams[opponentId].shortName}-${teams[opponentId].logo}--above`
                    )}
                  >
                    &nbsp;
                  </p>
                  <p>{limitString(teams[opponentId].shortName, 3)}</p>
                  <p>
                    <span
                      className={
                        winLoseDraw(
                          thisTeamId,
                          match.homeId,
                          match.homeScore,
                          match.awayScore
                        ) + "--big"
                      }
                    ></span>
                  </p>
                  <p>
                    {match.homeScore}-{match.awayScore}
                  </p>
                </div>
              )
            })}
          </div>
          <Link
            className="uppercase text-xs bg-violet-200 hover:bg-violet-900 hover:text-violet-100 transition-all"
            to="/results"
          >
            View Results
          </Link>
        </div>
      </div>
    )
  }
  const lastMatch = getLastMatchForTeam(matchGroups, thisTeamId)
  const topScorer = getTopScorer(
    players.filter((player) => player.teamId === thisTeamId)
  )

  const playerContractsNeedingAttention = contractAlert(
    user.teamId >= 0 ? teams[user.teamId].squadOrder : [],
    players
  )

  return (
    <>
      <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
        {news.length > 0 && (
          <>
            <section className="card">
              <header className="p-4 flex gap-4 items-center justify-between flex-col">
                <section className="flex gap-4 items-center">
                  <p className="hidden s:inline">Filter by</p>
                  {concerns.map(
                    (concern, i) =>
                      newsByType[concern].length > 0 && (
                        <Button
                          key={i}
                          label={concern}
                          selected={newsType === concern ? "selected" : false}
                          onClickFunction={(e) => setNewsType(concerns[i])}
                        />
                      )
                  )}
                </section>
                <section>
                  <button
                    className="text-xs py-1 px-2"
                    onClick={() => archiveAllNews()}
                  >
                    Archive all news
                  </button>
                </section>
              </header>
              <section className="max-h-[20rem] overflow-auto">
                {newsByType[newsType].map((newsItem, i) => {
                  return (
                    <section
                      className={
                        isArchived(newsItem) +
                        " flex flex-col s:flex-row gap-2 items-start s:items-center px-4 py-2 border-t border-dotted border-t-stone-200"
                      }
                      key={i}
                      onClick={() => {
                        dispatch(archiveNewsItem(newsItem.id))
                      }}
                    >
                      <p className="tracking-widest s:w-[15%] uppercase text-xs">
                        {moment(newsItem.date).format("MMM D, 'YY")}
                      </p>
                      <section className="flex items-center gap-4 justify-between w-full s:w-[85%]">
                        <p className="text-xs">{newsItem.body}.</p>
                        {newsItem.link !== "" && (
                          <Link
                            to={"/" + newsItem.link}
                            className="bg-violet-900 visited:text-white text-white rounded px-2 py-2 leading-3 transition-all text-xxs uppercase tracking-widest"
                          >
                            More
                          </Link>
                        )}
                      </section>
                    </section>
                  )
                })}
              </section>
            </section>
          </>
        )}
        {!news.length && <p>No news</p>}
      </div>
      <div className="s:p-2 m:p-3 l:p-4 overview grid gap-1 m:gap-3 l:gap-6 m:grid-cols-1 l:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 auto-rows-fr grow">
        {playerContractsNeedingAttention > 0 && (
          <div className="card pt-1 pb-4 px-2 animate-fade area--players alert">
            <h4 className="h--mono">Contracts Needing Attention</h4>
            <Link to="/contracts">Contracts</Link>
          </div>
        )}
        {getNextMatch()}
        {lastFiveMatches()}
        <div className="card pt-1 pb-4 px-2 animate-fade area--finance">
          <h4 className="text-xs uppercase text-slate-400 mb-4">Bank</h4>
          <div className="flex items-center flex-col gap-2">
            <h3 className="zero">
              ${teams[thisTeamId].bankBalance.toLocaleString()}
            </h3>
            <p>Balance</p>
          </div>
        </div>
        {/* {fixtureGroups.map(
                fixtureGroup => fixtureGroup.filter((fixture) => fixture.homeId === teamId)
                .map((fixture, i) => <p>{fixture.homeName} v. {fixture.awayName} {new Date(fixture.kickOff).toDateString()}</p>)
                )
            } */}
        {topScorer && (
          <div className="card pt-1 pb-4 px-2 animate-fade area--player">
            <h4 className="text-xs uppercase text-slate-400 mb-4">
              Top Scorer
            </h4>
            <div className="flex items-center flex-col gap-2">
              <span className="punch">{topScorer.goals}</span>
              <h3>{`${topScorer.firstName} ${topScorer.lastName}`}</h3>
            </div>
          </div>
        )}
        {lastMatch && (
          <div className="card pt-1 pb-4 px-2 animate-fade area--match">
            <h4 className="text-xs uppercase text-slate-400 mb-4">
              Last Match
            </h4>
            <div className="flex items-center flex-col gap-2">
              <h3 className="c">
                <span
                  className={getCssClassName(
                    `${teams[lastMatch.homeId].shortName}-${
                      teams[lastMatch.homeId].logo
                    }`
                  )}
                >
                  {teams[lastMatch.homeId].name}
                </span>
                <br />
                {lastMatch.homeScore}-{lastMatch.awayScore}
                <br />
                <span
                  className={getCssClassName(
                    `${teams[lastMatch.awayId].shortName}-${
                      teams[lastMatch.awayId].logo
                    }`
                  )}
                >
                  {teams[lastMatch.awayId].name}
                </span>
              </h3>
              {/* <Link to="/">Match Report</Link> */}
            </div>
          </div>
        )}
        <div className="card pt-1 pb-4 px-2 animate-fade area--board">
          <h4 className="text-xs uppercase text-slate-400 mb-4">Board</h4>
          <div className="flex items-center flex-col gap-2">
            <h3 className="zero">{calculateConfidence(board.confidence)}</h3>
            <p>Confidence</p>
          </div>
        </div>

        <div className="card pt-1 pb-4 px-2 animate-fade area--board">
          <h4 className="text-xs uppercase text-slate-400 mb-4">Stadium</h4>
          <div className="flex items-center flex-col gap-2">
            <p>{teams[thisTeamId].stadium.capacity.toLocaleString()}</p>
            <h3 className="zero">{teams[thisTeamId].stadium.name}</h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default Overview
