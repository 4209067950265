import React from "react"
import { useSelector } from "react-redux"
import moment from "moment"

import { pluralYear } from "../functions/pluralYear"

const Finances = () => {
  const user = useSelector((state) => state.user)
  const team = useSelector((state) => state.teams[user.teamId])
  const players = useSelector((state) => state.players)
  const usersPlayers = players.filter((player) => player.teamId === user.teamId)
  const usersPlayersIds = [...usersPlayers.map((player) => player.id)]
  const wagePerPeriod = (usersPlayersIds, period = 52) => {
    return (
      usersPlayersIds.reduce((accumulator, current) => {
        return accumulator + players[current].wage
      }, 0) * period
    )
  }
  const currentYearWage = wagePerPeriod(usersPlayersIds)
  let currentYear = moment(user.currentTime).format("YYYY")
  const wageByYear = getWageYears(usersPlayers)
  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
      <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">
        Finances
      </h1>
      <header className="center">
        <dl>
          <dt>Bank Balance:</dt>
          <dd className="accounting">£{team.bankBalance.toLocaleString()}</dd>
          <dt>Transfer budget available</dt>
          <dd className="accounting">
            £
            {calculateTransferBudget(
              team.bankBalance,
              currentYearWage
            ).toLocaleString()}
          </dd>
          <dt>Wage budget available</dt>
          <dd className="accounting">
            £
            {calculateWageBudget(
              team.bankBalance,
              currentYearWage
            ).toLocaleString()}
          </dd>
          {wageByYear.map((year, i) => (
            <React.Fragment key={i}>
              <dt>{parseInt(currentYear) + i} wage</dt>
              <dd className="accounting">{year.toLocaleString()}</dd>
            </React.Fragment>
          ))}
          <dt className="subtotal">
            Total wages over {pluralYear(wageByYear.length)}
          </dt>
          <dd className="accounting subtotal">
            {wageByYear
              .reduce((previous, current) => {
                return previous + current
              }, 0)
              .toLocaleString()}
          </dd>
        </dl>
      </header>
      <table className="table-- card block m:table overflow-x-auto">
        <caption>{currentYear} Income</caption>
        <thead>
          <tr>
            <th className="a-l">Income Type</th>
            <th className="a-r">Weekly</th>
            <th className="a-r">{currentYear} Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ticket Sales</td>
            <td className="accounting">
              £{(team.stadium.capacity * 50 * 0.75).toLocaleString()}
            </td>
            <td className="accounting">
              £{(team.stadium.capacity * 19 * 50 * 0.75).toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table-- card block m:table overflow-x-auto">
        <caption>{currentYear} Expenditure</caption>
        <thead>
          <tr>
            <th className="a-l">Expenditure Type</th>
            <th className="a-r">Weekly</th>
            <th className="a-r">{currentYear} Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Wages</td>
            <td className="accounting">£{currentYearWage.toLocaleString()}</td>
            <td className="accounting">
              £{wagePerPeriod(usersPlayersIds).toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
      {/* Gate receipts */}
      {/* Transfer expenditure */}
    </div>
  )
}
export default Finances

const calculateTransferBudget = (balance, currentYearWage) => {
  if (currentYearWage > balance) return 0
  return (balance - currentYearWage) / 10
}
const calculateWageBudget = (balance, currentYearWage) => {
  if (currentYearWage > balance) return 0
  return (balance - currentYearWage) / 200
}
const getWageYears = (players) => {
  let wageByYear = []
  players.forEach((player) => {
    for (let year = 0; year < player.contractExpiry; year++) {
      if (wageByYear[year] === undefined) wageByYear[year] = 0
      wageByYear[year] += player.wage * 52
    }
  })
  return wageByYear
}
