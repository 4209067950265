import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import "./css/app.css"
import "./css/tailwind-out.css"
import { useSelector } from "react-redux"
import Masthead from "./components/Masthead"
import NavLeft from './components/NavLeft'
import Overview from "./components/Overview"
import MatchResult from "./components/MatchResult"
import {TeamSelection} from "./components/TeamSelection"
import PlayerContracts from "./components/PlayerContracts"
import Bids from "./components/Bids"
import TransferList from "./components/TransferList"
import Transfers from "./components/Transfers"
import FreeAgents from "./components/FreeAgents"
import Results from "./components/Results"
import Fixtures from "./components/Fixtures"
import League from "./components/League"
import Finances from './components/Finances'
import Inbox from './components/Inbox'
import Team from './components/Team'
import LongList from './components/LongList'
import Cups from './components/Cups'
import Cup from './components/Cup'
import JoinOrCreateGame from './components/JoinOrCreateGame'
import Footer from './components/Footer'
import Ui from './components/Ui'


function App() {
  const user = useSelector(state => state.user)

  return (
    <Router>
      <Masthead />
      <section className="flex flex-col m:flex-row">
        {user.teamId > -1 && <NavLeft />}
        <Routes>
          <Route path="/match-result" element={
            <MatchResult />
          } />
          <Route path="/league" element={
            <League />
          } />
          <Route path="/matchdetail/:id" element={
            <Results />
          } />
          <Route path="/results" element={
            <Results />
          } />
          <Route path="/fixtures" element={
            <Fixtures />
          } />
          <Route path="/bids" element={
            <Bids />
          } />
          <Route path="/transfer-list" element={
            <TransferList />
          } />
          <Route path="/transfers" element={
            <Transfers />
          } />
          <Route path="/team-selection" element={
            <TeamSelection />
          } />
          <Route path="/free-agents" element={
            <FreeAgents />
          } />
          <Route path="/finances" element={
            <Finances />
          } />
          <Route path="/contracts" element={
            <PlayerContracts />
          } />
          <Route path="/inbox" element={
            <Inbox />
          } />
          <Route path="/long-list" element={
            <LongList />
          } />
          <Route path="/cups" element={
            <Cups />
          } />
          <Route path="/JoinOrCreateGame" element={
            <JoinOrCreateGame />
          } />
          <Route path="/team/:teamName" element={
            <Team />
          } />
          <Route path="/cup/:cupName" element={
            <Cup />
          } />
          <Route path="/ui" element={
            <Ui />
          } />
          <Route path="/" element={
            user.teamId > -1 ? <Overview /> : <JoinOrCreateGame />
          } />
        </Routes>
      </section>
      <Footer />
    </Router>
  )
}

export default App
