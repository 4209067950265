import store from '../store';
import workerSend from "../appWorker"
import { releasePlayer as releasePlayerLocal, addNews as addNewsLocal } from "../actions"
import { makeNews } from "../functions/addNews"

export default function releasePlayer (user, player, team, becauseExpired = false) {
  if (user.isLocalGame) {
    store.dispatch(releasePlayerLocal(player, user.teamId, player.contractExpiry * player.wage))
    if (player.teamId === user.teamId) {
      store.dispatch(addNewsLocal([
        makeNews(user.currentTime,
          team.id,
          becauseExpired ? `😭 ${player.firstName} ${player.lastName}'s contract has expired; they have today left the club` :
          `${player.firstName} ${player.lastName} Released by ${team.shortName}`,
          "",
          "transfer"
        )])
      )
    }
  } else {
    workerSend("releasePlayer", { playerId: player.id })
  }
}
