import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import getTeamCircle from "../functions/getTeamCircle"

const getCupIcon = (icon) => (
  <svg
    viewBox="0 0 16 16"
    className="w-4 h-4 hidden m:block"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"
    />
  </svg>
)

const getTransfersIcon = (icon) => (
  <svg
    viewBox="0 0 16 16"
    className="w-4 h-4 hidden m:block"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
    />
  </svg>
)

const getFinancesIcon = (icon) => (
  <svg
    viewBox="0 0 16 16"
    className="w-4 h-4 hidden m:block"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 3H1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1h-1z" />
    <path
      fillRule="evenodd"
      d="M15 5H1v8h14V5zM1 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H1z"
    />
    <path d="M13 5a2 2 0 0 0 2 2V5h-2zM3 5a2 2 0 0 1-2 2V5h2zm10 8a2 2 0 0 1 2-2v2h-2zM3 13a2 2 0 0 0-2-2v2h2zm7-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
  </svg>
)

const getNewsIcon = (icon) => (
  <svg
    viewBox="0 0 16 16"
    className="w-4 h-4 hidden m:block"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
    />
  </svg>
)

const getTeamIcon = (icon) => (
  <svg
    viewBox="0 0 16 16"
    className="w-4 h-4 hidden m:block"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
    />
    <path
      fillRule="evenodd"
      d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
    />
    <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z" />
  </svg>
)

export const contractAlert = (squadOrder, players) => {
  let needAttention = 0
  squadOrder.forEach((playerId) => {
    if (players[playerId].contractExpiry < 1) needAttention++
  })
  return needAttention
}

const bidAlert = (bids, TeamId) => {
  let totalBids = 0
  bids.forEach((bid) => {
    if (bid.sellingTeamId === TeamId && bid.status === "") {
      totalBids++
    }
  })
  return totalBids
}

function NavLeft() {
  const user = useSelector((state) => state.user)
  const teams = useSelector((state) => state.teams)
  const players = useSelector((state) => state.players)
  const market = useSelector((state) => state.market)

  const totalBids = bidAlert(market.bids, user.teamId)
  const playerContractsNeedingAttention =
    user.teamId > -1 ? contractAlert(teams[user.teamId].squadOrder, players) : 0

  const userHasTeam = user.teamId >= 0
  const isNews = user.news.filter((item) => item.archived === false)

  const pill = "py-1.5 rounded-full px-3 [&>span]:bg-[#BFB0FF]"
  const activePill = "bg-[#BFB0FF] py-1.5 rounded-full px-3"
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      {userHasTeam >= 0 && (
        <>
          <input
            type="checkbox"
            className="peer sr-only"
            id="drawer-toggle"
            checked={drawerOpen && "checked"}
            onInput={() => setDrawerOpen(!drawerOpen)}
          />
          <label
            className="m:hidden absolute top-4 right-5 z-50 p-2"
            htmlFor="drawer-toggle"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </label>
          <ul className="transition-all duration-500 transform translate-x-full m:translate-x-0 bg-[#fff] m:bg-transparent shadow-lg m:shadow-none w-[70vw] min-w-[70w] m:w-auto fixed m:static top-0 right-0 bottom-0 flex flex-col uppercase tracking-widest z-20 list-none top-0 gap-8 p-5 m:pt-5 peer-checked:translate-x-0 peer-checked:translate-x-0">
            <li className="text-xs">
              <div className="flex flex-col gap-1.5">
                <div className="flex -gap-4">
                  {getTeamCircle(teams?.[user.teamId]?.colors[3]?.other)}
                  {getTeamCircle(teams?.[user.teamId]?.colors[2]?.away)}
                  {getTeamCircle(teams?.[user.teamId]?.colors[1]?.secondary)}
                  {getTeamCircle(teams?.[user.teamId]?.colors[0]?.primary)}
                </div>
                {teams?.[user.teamId]?.name}
              </div>
            </li>
            <li className="text-xxs flex gap-1">
              {getNewsIcon()}
              <ul className="flex flex-col gap-5 py-1.5 -mt-1">
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    News
                    {isNews.length > 0 && (
                      <span className="ml-2 notification--bubble text-center radius-50 color-white inline-block font-mono font-normal p-0 rounded-full leading-4">
                        {isNews.length}
                      </span>
                    )}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="text-xxs flex gap-1">
              {getTeamIcon()}
              <ul className="flex flex-col gap-5 py-1.5 -mt-1">
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/team-selection"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Team
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="text-xxs flex gap-1">
              {getFinancesIcon()}
              <ul className="flex flex-col gap-5 py-1.5 -mt-1">
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/finances"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Money{" "}
                    {playerContractsNeedingAttention > 0 && (
                      <span className="ml-2 notification--bubble text-center radius-50 color-white inline-block font-mono font-normal p-0 rounded-full leading-4">
                        {playerContractsNeedingAttention}
                      </span>
                    )}
                  </NavLink>
                </li>
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/contracts"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Contracts
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="text-xxs flex gap-1">
              {getCupIcon()}
              <ul className="flex flex-col gap-5 py-1.5 -mt-1">
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/fixtures"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Fixtures
                  </NavLink>
                </li>
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/results"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Results
                  </NavLink>
                </li>
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/league"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    League
                  </NavLink>
                </li>
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/cups"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Cups
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="text-xxs flex gap-1">
              {getTransfersIcon()}
              <ul className="flex flex-col gap-5 py-1.5 -mt-1">
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/transfers"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Transfers
                  </NavLink>
                </li>
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/bids"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Bids
                    {totalBids > 0 && (
                      <span className="ml-2 notification--bubble text-center radius-50 color-white inline-block font-mono font-normal p-0 rounded-full leading-4">
                        {totalBids}
                      </span>
                    )}
                  </NavLink>
                </li>
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/transfer-list"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Transfer List
                  </NavLink>
                </li>
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/long-list"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Long List
                  </NavLink>
                </li>
                <li className="text-xxs uppercase tracking-widest leading-none">
                  <NavLink
                    to="/free-agents"
                    className={({ isActive }) => (isActive ? activePill : pill)}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    Free Agents
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </>
      )}
    </>
  )
}

export default NavLeft
