import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { signFreeAgent } from "../actions"
import abbreviatePosition from "../functions/abbreviatePosition"
import Modal from "react-modal"
import PlayerDetail from "./PlayerDetail"
import workerSend from "../appWorker"
import Stars from "./Stars"
import Button from "./Button"
import average from "../functions/average"

function starstoInt(stars = "★") {
  switch (stars) {
    case "★★★★":
      return 4
    case "★★★":
      return 3
    case "★★":
      return 2
    default:
      return 1
  }
}
const intToPercentage = (no) => no * 20

function FreeAgents() {
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()
  const [filteredPosition, setFilteredPosition] = useState("all")
  const isSelected = (position) =>
    filteredPosition === position ? "selected" : undefined
  const players = useSelector((state) => state.players)
  const team = useSelector((state) => state.teams[user.teamId])

  const [filterByStars, setFilterByStars] = useState("★")

  const checkForFilters = () => {
    const signAgent = (player) => {
      if (user.isLocalGame) {
        dispatch(signFreeAgent(player, team.id))
      } else {
        workerSend("signAgent", { playerId: player.id })
      }
    }
    let filteredPlayers = players.filter(
      (player) =>
        average([
          player.rating.mental,
          player.rating.technical,
          player.rating.physical,
        ]) >= intToPercentage(starstoInt(filterByStars))
    )
    if (filteredPosition !== "all") {
      filteredPlayers = filteredPlayers.filter(
        (player) => player.position === filteredPosition
      )
    }

    return filteredPlayers
      .filter((player) => player.teamId === -1)
      .map((player) => (
        <tr key={player.id}>
          <td
            className="onClick"
            onClick={() => setViewPlayer(player)}
          >{`${player.firstName} ${player.lastName}`}</td>
          <td>{abbreviatePosition(player.position)}</td>
          <td>
            <Stars player={player} />
          </td>
          <td>{player.age}</td>
          <td className="accounting">{player.wage.toLocaleString()}</td>
          <td>
            <button onClick={() => signAgent(player)} className="btn third">
              Sign
            </button>
          </td>
        </tr>
      ))
  }

  const [viewPlayer, setViewPlayer] = useState(false)

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
      {viewPlayer && (
        <Modal ariaHideApp={false} isOpen={viewPlayer.id >= 0}>
          {<PlayerDetail player={viewPlayer} />}
          <button onClick={() => setViewPlayer(false)}>Close</button>
        </Modal>
      )}
      <header className="center">
        <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">
          Free Agents
        </h1>
        <nav className="flex flex-col s:flex-row justify-center gap-4">
          <ul className="flex items-center justify-center gap-4 mb-4">
            <li>
              Filter <span className="hidden s:inline">by position</span>
            </li>
            <li>
              <Button
                label="GK"
                selected={isSelected("Goalkeeper")}
                onClickFunction={() => setFilteredPosition("Goalkeeper")}
              />
            </li>
            <li>
              <Button
                label="DF"
                selected={isSelected("Defender")}
                onClickFunction={() => setFilteredPosition("Defender")}
              />
            </li>
            <li>
              <Button
                label="MF"
                selected={isSelected("Midfield")}
                onClickFunction={() => setFilteredPosition("Midfield")}
              />
            </li>
            <li>
              <Button
                label="FW"
                selected={isSelected("Forward")}
                onClickFunction={() => setFilteredPosition("Forward")}
              />
            </li>
          </ul>
          <ul className="flex">
            <label htmlFor="min-stars">
              <span className="inline s:hidden">Min.</span>
              <span className="hidden s:inline">Minimum ability</span>
            </label>
            <select
              value={filterByStars}
              id="min-stars"
              onChange={(e) => {
                setFilterByStars(e.target.value)
              }}
            >
              <option>★★★★</option>
              <option>★★★</option>
              <option>★★</option>
              <option>★</option>
            </select>
          </ul>
        </nav>
      </header>
      <table className="table-- card block m:table overflow-x-auto">
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Rating</th>
            <th>Age</th>
            <th>Wage</th>
            <th>Sign</th>
          </tr>
        </thead>
        <tbody>{checkForFilters()}</tbody>
      </table>
    </div>
  )
}

export default FreeAgents
