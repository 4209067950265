import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import Modal from "react-modal"

import abbreviatePosition from "../functions/abbreviatePosition"
import { getTeamIdByShortName } from "../functions/teams"
import getTopScorer from "../functions/getTopScorer"
import calculateStature from "../functions/calculateStature"
import PlayerDetail from "./PlayerDetail"
import BidForPlayer from "./BidForPlayer"
import Stars from "./Stars"
import Button from "./Button"

function Team() {
  const teams = useSelector((state) => state.teams)
  const players = useSelector((state) => state.players)
  const [player, setPlayer] = useState(false)
  const user = useSelector((state) => state.user)
  let { teamName } = useParams()
  teamName = decodeURI(teamName)

  const teamId = getTeamIdByShortName(teams, teamName)

  const isUsersTeam =
    teams[teamId].id === useSelector((state) => state.user.teamId)

  const topScorer = getTopScorer(
    players.filter((player) => player.teamId === teamId)
  )

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
      <header className="center">
        <div className="flex flex-col gap-1.5 items-center">
          <div className="flex -gap-4">
            <span
              className="rounded-full"
              style={{
                backgroundColor: `hsl(${teams?.[teamId]?.colors[3]?.other})`,
                display: "block",
                height: "1rem",
                width: "1rem",
                margin: "-2px",
              }}
            ></span>
            <span
              className="rounded-full"
              style={{
                backgroundColor: `hsl(${teams?.[teamId]?.colors[2]?.away})`,
                display: "block",
                height: "1rem",
                width: "1rem",
                margin: "-2px",
              }}
            ></span>
            <span
              className="rounded-full"
              style={{
                backgroundColor: `hsl(${teams?.[teamId]?.colors[1]?.secondary})`,
                display: "block",
                height: "1rem",
                width: "1rem",
                margin: "-2px",
              }}
            ></span>
            <span
              className="rounded-full"
              style={{
                backgroundColor: `hsl(${teams?.[teamId]?.colors[0]?.primary})`,
                display: "block",
                height: "1rem",
                width: "1rem",
                margin: "-2px",
              }}
            ></span>
          </div>
        </div>
        <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">
          {teams[teamId].name}
        </h1>
      </header>
      {player && (
        <Modal ariaHideApp={false} isOpen={player.id >= 0}>
          {<PlayerDetail player={player} />}
          {<BidForPlayer player={player} setPlayer={setPlayer} />}
          <Button label="Close" onClickFunction={() => setPlayer(false)}>
            Close
          </Button>
        </Modal>
      )}
      <ul class="flex flex-col m:flex-row justify-between gap-4 grow">
        {!isUsersTeam && (
          <li>
            <div className="text-gray-400">Top Scorer</div>
            <div
              className="text-3xl font-sans onClick"
              onClick={() => setPlayer(topScorer)}
            >
              {topScorer.firstName} {topScorer.lastName}
            </div>
          </li>
        )}
        <li>
          <div className="text-gray-400">Stadium:</div>
          <div className="text-3xl font-sans">
            {teams[teamId].stadium.name} (
            {teams[teamId].stadium.capacity.toLocaleString()})
          </div>
        </li>
        <li>
          <div className="text-gray-400">Continent:</div>
          <div className="text-3xl font-sans">{teams[teamId].continent}</div>
        </li>
        <li>
          <div className="text-gray-400">Stature:</div>
          <div className="text-3xl font-sans">
            {calculateStature(teams[teamId].stature)}
          </div>
        </li>
      </ul>
      <div className="card">
        <table className="table--lineup">
          <thead>
            <tr>
              <th>Name</th>
              <th>Position</th>
              <th>Rating</th>
              <th>
                <abbr title="Appearances">APP</abbr>
              </th>
              <th>
                <abbr title="Goals">GLS</abbr>
              </th>
              <th>Form</th>
            </tr>
          </thead>
          <tbody>
            {teams[teamId].squadOrder.map((playerId, i) => {
              let player = players[playerId]
              return (
                <tr key={player.id}>
                  <td className="onClick" onClick={() => setPlayer(player)}>
                    {`${player.firstName} ${player.lastName}`}
                    {player.loanListed && (
                      <i className="icon--loan-listed ml-2"></i>
                    )}
                    {player.transferListed && (
                      <i className="icon--transfer-listed ml-2"></i>
                    )}
                  </td>
                  <td className={player.position.toLowerCase()}>
                    {abbreviatePosition(player.position)}
                  </td>
                  <td>
                    <Stars player={player} />
                  </td>
                  <td>{player.apperearances}</td>
                  <td>{player.goals}</td>
                  <td>{player.form}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Team
