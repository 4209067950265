import React, { useState } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { Link } from "react-router-dom"

import getCssClassName from "../functions/getCssClassName"

function Transfers() {
  const teams = useSelector((state) => state.teams)
  const players = useSelector((state) => state.players)
  const market = useSelector((state) => state.market)
  const seasons = useSelector((state) => state.seasons)
  const [yearIndex] = useState(0)

  const getRecordTransfers = (bids, howMany = 5) => {
    const transfers = bids.filter(
      (bid) => bid.status === "accepted" && bid.type === "transfer"
    )
    if (transfers.length >= howMany) {
      return transfers
        .sort((a, b) => a.fee < b.fee)
        .map((bid, i) => (
          <tr key={i}>
            <td>{moment(bid.dateTime).format("MMM D, YYYY")}</td>
            <td>
              {players[bid.playerId].firstName} {players[bid.playerId].lastName}
            </td>
            <td
              className={
                getCssClassName(teams[bid.sellingTeamId].shortName) +
                "-" +
                teams[bid.sellingTeamId].logo
              }
            >
              <Link to={`/team/${teams[bid.sellingTeamId].shortName}`}>
                {teams[bid.sellingTeamId].shortName}
              </Link>
            </td>
            <td
              className={
                getCssClassName(teams[bid.buyingTeamId].shortName) +
                "-" +
                teams[bid.buyingTeamId].logo
              }
            >
              <Link to={`/team/${teams[bid.buyingTeamId].shortName}`}>
                {teams[bid.buyingTeamId].shortName}
              </Link>
            </td>
            <td className="accounting">£{bid.fee.toLocaleString()}</td>
          </tr>
        ))
        .slice(0, howMany)
    }
  }

  const areTransfers = market.bids.length > 0 ? true : false
  const getTransfers = (bids) =>
    bids
      .filter(
        (bid) =>
          bid.status === "accepted" &&
          parseInt(moment(bid.dateTime).format("YYYY")) ===
            seasons[0][yearIndex].year
      )
      .map((bid, i) => (
        <tr key={i}>
          <td>{moment(bid.dateTime).format("MMM D, YYYY")}</td>
          <td>{`${players[bid.playerId].firstName} ${
            players[bid.playerId].lastName
          }`}</td>
          <td
            className={
              getCssClassName(teams[bid.sellingTeamId].shortName) +
              "-" +
              teams[bid.sellingTeamId].logo
            }
          >
            <Link to={`/team/${teams[bid.sellingTeamId].shortName}`}>
              {teams[bid.sellingTeamId].shortName}
            </Link>
          </td>
          <td
            className={
              getCssClassName(teams[bid.buyingTeamId].shortName) +
              "-" +
              teams[bid.buyingTeamId].logo
            }
          >
            <Link to={`/team/${teams[bid.buyingTeamId].shortName}`}>
              {teams[bid.buyingTeamId].shortName}
            </Link>
          </td>
          <td className="accounting">
            {bid.fee !== undefined ? bid.fee.toLocaleString() : "Loan"}
          </td>
        </tr>
      ))

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
      <header className="center">
        <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">
          All Transfers
        </h1>
      </header>
      <table className="table--ghost card">
        <caption>Highest Transfer Fees</caption>
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>From</th>
            <th>To</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody>{getRecordTransfers(market.bids)}</tbody>
      </table>
      <table className="table--ghost card">
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>From</th>
            <th>To</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody>
          {areTransfers ? (
            getTransfers(market.bids)
          ) : (
            <tr>
              <td colSpan="5">No transfers</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Transfers
