import { REHYDRATE } from 'redux-persist';
import workerSend from "../appWorker"
import { shouldMarketBeOpen, makeMarketOpenNews } from "../functions/market"

const initialState = {
  id: null,
  gameId: null,
  isLocalGame: null,
  serverConnecting: false,
  teamId: -1,
  managerName : "Ian Curtis",
  currentTime: "2021-07-01T12:00:00.000Z",
  news: [],
  longList: [],
}

const userReducer = (state = initialState, action) => {
  let user = {...state}, news

  switch(action.type){
    case 'newGame':
      return {...initialState, gameId: action.userId, managerName: action.managerName, isLocalGame: true, id: action.userId }
    case REHYDRATE:
      if (action.payload !== undefined && action.payload.user) {
        user = action.payload.user
        if (!action.payload.user.isLocalGame && action.payload.user.gameId && action.payload.user.teamId > -1) {
          workerSend("appInit", {
            userId: action.payload.user.id,
            gameId: action.payload.user.gameId,
          }, true, user)
        } else {
          user.serverConnecting = false
        }
        return user
      } else {
        return state
      }
    case 'serverConnecting':
      user.serverConnecting = true
      return user
    case 'leaveGame':
    case 'gameNotFound':
      user.id = null
      user.gameId = null
      user.isLocalGame = null
      user.teamId = -1
      user.serverConnecting = false
      return user
    case 'incrementalServerData':
    case 'fullServerData':
      const newData = action.user
      if (newData !== undefined) {
        return {...user, ...newData, serverConnecting: false, isLocalGame: false }
      }
      user.serverConnecting = false
      return user
    case 'clubSelected' :
      user.teamId = action.teamId
      return user
    case 'progressToDate' :
      const marketWasOpen = shouldMarketBeOpen(user.currentTime)
      const shouldBeOpen = shouldMarketBeOpen(action.newDate)
      if (marketWasOpen !== shouldBeOpen) {
        news = [...user.news]
        news.unshift(makeMarketOpenNews(user.currentTime, user.teamId, shouldBeOpen))
        user.news = news
      }
      user.currentTime = action.newDate
      return user
    case 'addNews' :
      news = [...user.news]
      action.news.forEach(newsItem => {
        news.unshift(newsItem)
        newsItem.id = news.length-1
      })
      user.news = news
      return user

    case 'addPlayerToLongList':
      user.longList = [...user.longList]
      user.longList.push(action.playerId)
      return user

    case 'signFreeAgent':
      removeFromLongList(user, action.player.id)
      return user
    case 'bidAccepted':
      if (action.bid.buyingTeamId === user.teamId) removeFromLongList(user, action.bid.playerId)
      return user
    case 'removePlayerFromLongList':
      removeFromLongList(user, action.playerId)
      return user

    case 'archiveNewsItem':
      let itemIndex = user.news.findIndex(item => item.id === action.newsItemId)
      news = {...user.news[itemIndex]}
      news.archived = true
      user.news[itemIndex] = news
      return user

    case 'archiveAllNewsItems':
      news = [...user.news]
      news.forEach((item, i) => {
          let newItem = { ...item }
          newItem.archived = true
          user.news[i] = newItem
      })
      return user

    case 'redirectedForMatchPlayed':
      delete user.played
      return user
      
    default:
      return state
  }
}

const removeFromLongList = (user, playerId) => {
  user.longList = [...user.longList]
  let indexInList = user.longList.findIndex(player => player === playerId)
  if (indexInList >= 0) user.longList.splice(indexInList, 1)
}

export default userReducer;
