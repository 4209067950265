import playersData, { newPlayer } from "../data/players"
import { updatePlayerTangibles, playerTraining, progressPlayerYear } from "../functions/players"

const playersReducer = (state = playersData, action) => {
  let players = [...state]
  let player
  const newData = action.players

  switch (action.type) {
    case 'newGame':
      return playersData
    case 'gameJoined':
      return action.players
    case 'incrementalServerData':
        return (newData !== undefined) ? newData : state
    case 'fullServerData':
        return (newData !== undefined) ? newData : []
    case 'releasePlayer':
      player = { ...players[action.player.id] }
      player.teamId = -1
      player.contractExpiry = 0
      player.transferListed = false
      player.loanListed = false
      // TODO:  player.contractExpiry, player.wage
      players[action.player.id] = player
      return players

    case 'extendPlayer':
      player = { ...players[action.player.id] }
      player.contractExpiry = action.years
      // TODO:  player.contractExpiry, player.wage
      players[action.player.id] = player
      return players

    case 'signFreeAgent':
      player = { ...players[action.player.id] }
      player.teamId = action.teamId
      // TODO:  player.contractExpiry, player.wage
      players[action.player.id] = player
      return players

    case 'nextYear':
      players.forEach((player, i) => {
        player = { ...player }
        players[player.id] = player
        progressPlayerYear(player, action.gameYears)
        if ( player.age > 32 && player.isRetiring !== true )
          player.isRetiring = Math.floor((getRandomInt(55,75) + player.age) / 100) ? true : false
      })

      // Generate some new players
      for (let i = 0; i < 100; i++) players.push(newPlayer(players.length, -1, action.gameYears))

      return players

    // Thinking aloud: In transfer window: AI teams know their weakest position (GK, DF, MF, FW) rating-wise
    // They'll bid for another team's player if:
    // [1] his position matches their need,
    // [2] his rating value is greater than the average of their weakest position (e.g. They have: GK: 44, GK: 66; 55 average. Transfer listed player's rating > 55)
    // [3] If they have enough money to afford the bid
    // If bids are not responded to in a single window they are rejected on the last turn

    case 'endPlayerLoan':
      player = { ...players[action.playerId] }
      player.borrowingTeamId = null
      player.loanExpiry = null
      player.loanShirtNumber = null
      players[action.playerId] = player
      return players

    case 'bidAccepted':
      player = { ...players[action.bid.playerId] }
      player.transferListed = false
      player.loanListed = false
      if (action.bid.type === "loan") {
        player.borrowingTeamId = action.bid.buyingTeamId
        player.loanExpiry = 1
        player.loanShirtNumber = action.bid.newShirtNumber
      } else {
        player.teamId = action.bid.buyingTeamId
        player.shirtNumber = action.bid.newShirtNumber
      }
      // TODO:  player.contractExpiry, player.wage
      players[action.bid.playerId] = player
      return players

    case 'togglePlayerTransferStatus':
      player = { ...players[action.player.id] }
      player.transferListed = !player.transferListed
      players[action.player.id] = player
      return players

    case 'togglePlayerLoanStatus':
      player = { ...players[action.player.id] }
      player.loanListed = !player.loanListed
      players[action.player.id] = player
      return players


    case 'matchPlayed':
      const result = action.result
      updatePlayersTangibles(players, result.homePlayerIds, result.homeScorers, result.homeScore - result.awayScore)
      updatePlayersTangibles(players, result.awayPlayerIds, result.awayScorers, result.awayScore - result.homeScore)
      return players

    // TODO: player injuries
    // case 'playerInjured':

    case 'playersTraining':
      playersTraining(action.teams, players)
      return players

    case 'promotePlayerToSeniorSquad' :
      player = { ...players[action.playerId] }
      player.isSenior = true
      players[action.playerId] = player
      return players

    case 'demotePlayerToYouthSquad' :
      player = { ...players[action.playerId] }
      player.isSenior = false
      players[action.playerId] = player
      return players

    case 'testHelperExaggerateForm':
      player = { ...players[action.player.id] }
      if (player.form > 50 && player.form < 100) {
        player.form += 5
      } else if (player.form < 50 && player.form > 25) {
        player.form -= 5
      }
      return players

    default:
      return state
  }
};

const updatePlayersTangibles = (players, playerIds, scorers, netGoals) => {
  players.filter(player => playerIds.includes(player.id)).forEach(player => {
    let updatedPlayer = { ...player }
    updatePlayerTangibles(updatedPlayer, scorers.find(goalScorer => goalScorer.playerId === player.id), netGoals)
    players[player.id] = updatedPlayer
  })
}

const playersTraining = (teams, players) => {
  players.filter(player => player.teamId > -1).forEach(player => {
    let updatedPlayer = { ...player }
    playerTraining(teams[player.teamId], updatedPlayer)
    players[player.id] = updatedPlayer
  });

}

const getRandomInt = (min, max) => Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min)) + Math.ceil(min))

export default playersReducer;
