import React from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { getNextUserFixture } from "../localFunctions/nextTurn"
import moment from "moment"

export const contractAlert = (squadOrder, players) => {
	let needAttention = 0
	squadOrder.forEach(playerId => {
		if (players[playerId].contractExpiry < 1)
			needAttention++
	})
	return needAttention
}

const bidAlert = (bids, TeamId) => {
	let totalBids = 0
	bids.forEach(bid => {
		if (bid.sellingTeamId === TeamId && bid.status === "") {
			totalBids++
		}
	})
	return totalBids
}

function NavHorizontal() {
	const user = useSelector(state => state.user)
	const teams = useSelector(state => state.teams)
	const leagues = useSelector(state => state.leagues)
	const cups = useSelector(state => state.cups)
	const players = useSelector(state => state.players)
	const market = useSelector(state => state.market)

	const totalBids = bidAlert(market.bids, user.teamId)
	const playerContractsNeedingAttention = user.teamId > -1 ? contractAlert(teams[user.teamId].squadOrder, players) : 0

	const nextFixtureForTeam = getNextUserFixture()

	const nextMatchDetails = () => {
		if (nextFixtureForTeam) {
			const competitions = nextFixtureForTeam.leagueId >= 0 ? leagues : cups
			const competitionId = nextFixtureForTeam.leagueId >= 0 ? nextFixtureForTeam.leagueId : nextFixtureForTeam.cupId
			const competitionName = competitions[competitionId].name
			const opponentName =
				nextFixtureForTeam.homeId === user.teamId
					? teams[nextFixtureForTeam.awayId].shortName
					: teams[nextFixtureForTeam.homeId].shortName
			const daysTilNextFixture = moment(nextFixtureForTeam.kickOff).diff(moment(user.currentTime), "days")
			const showDaysTilNextFixture = days => {
				let returnValue = `${days} days`
				if (days === 0)
					returnValue = `today`
				if (days === 1)
					returnValue = `1 day`
				return returnValue
			}
			return (
				<p className="vidiprinter">Next Match: {`${opponentName} (${competitionName})`} {new Date(nextFixtureForTeam.kickOff).toDateString()} ({showDaysTilNextFixture(daysTilNextFixture)})</p>
			)
		}
		return null
	}

	return (
		<>
			{user.teamId >= 0 && (
				nextMatchDetails()
			)}
			{user.teamId >= 0 && (
				<ul className="nav--horizontal flex uppercase tracking-widest z-20 sticky list-none top-0 justify-center m:justify-evenly text-white bg-slate-900">
					<li className="text-xxs">
						<NavLink to="/team-selection" className={"flex relative hover:bg-slate-300 hover:text-slate-900 items-center"}>
							<svg viewBox="0 0 16 16" className="w-4 h-4 mr-4 hidden m:block" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
								<path fillRule="evenodd" d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
								<path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z" />
							</svg>
							Team
						</NavLink>
					</li>
					<li className="text-xxs">
						<NavLink to="/finances" className={"flex relative hover:bg-slate-300 hover:text-slate-900 items-center"}>
							<svg viewBox="0 0 16 16" className="w-4 h-4 mr-4 hidden m:block" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path d="M14 3H1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1h-1z" />
								<path fillRule="evenodd" d="M15 5H1v8h14V5zM1 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H1z" />
								<path d="M13 5a2 2 0 0 0 2 2V5h-2zM3 5a2 2 0 0 1-2 2V5h2zm10 8a2 2 0 0 1 2-2v2h-2zM3 13a2 2 0 0 0-2-2v2h2zm7-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
							</svg>
							Money {playerContractsNeedingAttention > 0 && (
								<span className="notification--bubble text-center radius-50 color-white inline-block font-mono foont-normal p-1 rounded-full leading-4 text-white absolute -top-[.5ch] -right-[.5ch] bg-orange">
									{playerContractsNeedingAttention}
								</span>
							)}
						</NavLink>
					</li>
					<li className="text-xxs">
						<NavLink to="/league" className={"flex relative hover:bg-slate-300 hover:text-slate-900 items-center"}>
							<svg viewBox="0 0 16 16" className="w-4 h-4 mr-4 hidden m:block" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z" />
							</svg>
							Matches
						</NavLink>
					</li>
					<li className="text-xxs">
						<NavLink to="/bids" className={"flex relative hover:bg-slate-300 hover:text-slate-900 items-center"}>
							<svg viewBox="0 0 16 16" className="w-4 h-4 mr-4 hidden m:block" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
							</svg>
							Transfers{" "}
							{totalBids > 0 && (
								<span className="notification--bubble text-center radius-50 color-white inline-block font-mono foont-normal p-1 rounded-full leading-4 text-white absolute -top-[.5ch] -right-[.5ch] bg-orange">
									{totalBids}
								</span>
							)}
						</NavLink>
					</li>
				</ul>
			)}
		</>
	)
}

export default NavHorizontal
