import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import workerSend from "../appWorker"
import Button from "./Button"

import { archiveNewsItem, archiveAllNewsItems } from "../actions"

function Inbox() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const teams = useSelector((state) => state.teams)
  const news = user.news
  const concerns = ["match", "players", "transfer", "milestone"]
  const newsByType = []
  concerns.forEach((concern) => {
    newsByType[concern] = news.filter((item) => item.concern === concern)
  })
  const [newsType, setNewsType] = useState(
    Object.keys(newsByType)
      .filter((concern) => newsByType[concern].length)
      .shift() || concerns[0]
  )
  const isArchived = (item) => {
    return item.archived === false ? " font-bold" : ""
  }

  const archiveAllNews = () => {
    if (user.isLocalGame) {
      dispatch(archiveAllNewsItems())
    } else {
      workerSend("archiveAllNews")
    }
  }

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
      {news.length > 0 && (
        <>
          <header className="s:flex justify-between py-4">
            <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">
              {teams[user.teamId].shortName} News
            </h1>
            <button
              className="text-xs py-1 px-2"
              onClick={() => archiveAllNews()}
            >
              Archive all news
            </button>
          </header>
          <section className="card">
            <header className="p-4 flex gap-4 items-center">
              <p>Filter by</p>
              {concerns.map(
                (concern, i) =>
                  newsByType[concern].length > 0 && (
                    <Button
                      key={i}
                      label={concern}
                      selected={newsType === concern ? "selected" : false}
                      onClickFunction={(e) => setNewsType(concerns[i])}
                    />
                  )
              )}
            </header>
            <section className="max-h-[40rem] overflow-auto">
              {newsByType[newsType].map((newsItem, i) => {
                return (
                  <section
                    className={
                      isArchived(newsItem) +
                      " flex items-center px-4 py-2 border-t border-dotted border-t-stone-200"
                    }
                    key={i}
                    onClick={() => {
                      dispatch(archiveNewsItem(newsItem.id))
                    }}
                  >
                    <p className="tracking-widest w-[15%] uppercase text-xs">
                      {moment(newsItem.date).format("MMM D, 'YY")}
                    </p>
                    <section className="flex items-center gap-4 justify-between w-[85%]">
                      <p className="text-xs">{newsItem.body}.</p>
                      {newsItem.link !== "" && (
                        <Link
                          to={"/" + newsItem.link}
                          className="bg-violet-900 visited:text-white text-white rounded px-2 py-2 leading-3 transition-all text-xxs uppercase tracking-widest"
                        >
                          More
                        </Link>
                      )}
                    </section>
                  </section>
                )
              })}
            </section>
          </section>
        </>
      )}
      {!news.length && <p>No news</p>}
    </div>
  )
}

export default Inbox
