import React, { useState } from "react"
import { Link } from "react-router-dom"
import Modal from "react-modal"
import { useSelector, useDispatch } from "react-redux"
import FormationSelection from './FormationSelection'
import Lineup from './Lineup'

import {
  teamReady,
} from "../../actions"

import PlayerDetail from "../PlayerDetail"
import workerSend from "../../appWorker"
import Button from "../Button"
import nextTurn, { isUserMatchDay } from "../../localFunctions/nextTurn"

// TODO refactor to update a 'staged' team state (and then the websocket) on a 'submit'
// for tactic, formation and squadOrder (and anything else changed here)
// TODO Ensure websocket update doesn't overwrite these local values except on appInit
// Maybe just put these in hooks and lose them on refresh?

export function TeamSelection() {
  const user = useSelector((state) => state.user)
  const team = useSelector((state) => state.teams[user.teamId])

  const dispatch = useDispatch()
  const [viewPlayer, setViewPlayer] = useState(false)

  const setTeamReady = (teamId) => {
    if (user.isLocalGame) {
      dispatch(teamReady(team.id))
    } else {
      workerSend("teamReady", {})
    }
    nextTurn()
  }

  const proceedToMatch = () => {
    return (
      <footer className="p-4 text-center">
        {user.isLocalGame ? (
          <Link
            className="btn first"
            onClick={() => setTeamReady(team.id)}
            to="/match-result"
          >
            Proceed to match
          </Link>
        ) : (
          <Link className="btn first" onClick={() => setTeamReady(team.id)}>
            Proceed to match {user.ready && <span className="rotating">X</span>}
          </Link>
        )}
      </footer>
    )
  }

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4 grow">
      {isUserMatchDay() && proceedToMatch()}
      {viewPlayer && (
        <Modal ariaHideApp={false} isOpen={viewPlayer.id >= 0}>
          {<PlayerDetail player={viewPlayer} />}
          <Button onClickFunction={() => setViewPlayer(false)} label="Close" />
        </Modal>
      )}
      <FormationSelection />
      <Lineup setViewPlayer={setViewPlayer} />
    </div>
  )
}