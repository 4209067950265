import React, { useState, useEffect } from "react"
import moment from "moment"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import nextTurn, {
  isUserMatchDay,
  isPreSeason,
} from "../localFunctions/nextTurn"
import { redirectedForMatchPlayed } from "../actions"
import { getNextUserFixture } from "../localFunctions/nextTurn"

function Masthead() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const teams = useSelector((state) => state.teams)
  const [askedPermission, setAskedPermission] = useState(
    Notification.permission === "granted" ||
      Notification.permission === "denied"
  )

  useEffect(() => {
    // If online game and match has now played..
    if (user.played) {
      // Could use a banner / notification to be more subtle
      navigate("/match-result")
      dispatch(redirectedForMatchPlayed())
    }
  }, [dispatch, navigate, user.played])

  const requestPermission = () => {
    Notification.requestPermission(function (status) {
      setAskedPermission(status === "granted" || status === "denied")
    })
  }

  const spinner = () => user.ready && <span className="rotating">X</span>

  const nextFixtureForTeam = getNextUserFixture()
  const nextMatchDetails = () => {
    if (nextFixtureForTeam) {
      const opponentName =
        nextFixtureForTeam.homeId === user.teamId
          ? teams[nextFixtureForTeam.awayId].shortName
          : teams[nextFixtureForTeam.homeId].shortName
      const daysTilNextFixture = moment(nextFixtureForTeam.kickOff).diff(
        moment(user.currentTime),
        "days"
      )
      const showDaysTilNextFixture = (days) => {
        let returnValue = `${days} days`
        if (days === 0) returnValue = `today`
        if (days === 1) returnValue = `1 day`
        return returnValue
      }
      return (
        <Link to="/select-team">
          Next Match: {`${opponentName}`}{" "}
          {new Date(nextFixtureForTeam.kickOff).toDateString()} (
          {showDaysTilNextFixture(daysTilNextFixture)})
        </Link>
      )
    }
    return null
  }

  return (
    <header className="flex flex-col m:flex-row gap-4 justify-between p-4 masthead">
      <section className="flex flex-row justify-between items-center">
        <h1 className="logo mr-4 font-sans font-black text-3xl flex items-center gap-2">
          <Link to="/" className="no-underline">
            <span>U</span>
            <span className="hidden s:inline">nbeaten </span>
            <span>R</span>
            <span className="hidden s:inline">un</span>
          </Link>
          {user.teamId > -1 && teams[user.teamId].unbeatenRun > 0 && (
            <span className="grid items-center justify-center h-8 w-8 bg-violet-100 rounded-full">
              <span className="font-serif italic font-medium text-lg leading-[0]">
                {teams[user.teamId].unbeatenRun}
              </span>
            </span>
          )}
        </h1>
      </section>
      {user.teamId > -1 && (
        <>
          <nav className="text-right flex justify-end items-center gap-4 flex-col s:flex-row">
            <span className="tracking-widest uppercase text-xxs">
              {nextMatchDetails()}
            </span>
            <span className="tracking-widest uppercase text-xxs">
              {new Date(user.currentTime).toDateString()}
            </span>
            {!askedPermission && !user.isLocalGame && (
              <button
                onClick={() => requestPermission()}
                className="btn second"
              >
                Enable Notifications
              </button>
            )}
            {isUserMatchDay() ? (
              <Link className="btn first" to="/team-selection">
                Match Prep {spinner()}
              </Link>
            ) : (
              <Link className="btn first" onClick={() => nextTurn()}>
                Next Day {spinner()}
              </Link>
            )}
            {isPreSeason() && (
              <Link className="btn second" onClick={() => nextTurn(true)}>
                {`End pre-season`}
                {user.fastForward && <span className="rotating">X</span>}
              </Link>
            )}
            {user.gameWaiting && (
              <span className="warning">Game waiting on you!</span>
            )}
          </nav>
        </>
      )}
    </header>
  )
}

export default Masthead
