const calculateStature = (stature) => {
  if (stature <= 20) {
    return "Small"
  } else if (stature <= 40) {
    return "Average"
  } else if (stature <= 60) {
    return "Large"
  } else if (stature <= 80) {
    return "Large"
  } else if (stature > 81) {
    return "Huge"
  }
}
export default calculateStature
