const getNextAvailableShirt = (players, playerIds, excluding = []) => {
  const unavailableNumbers = {}

  playerIds.forEach(id => {
    unavailableNumbers[players[id].loanShirtNumber ?? players[id].shirtNumber] = true
  })

  excluding.forEach(id => {
    unavailableNumbers[id] = true
  })
  
  for (let i = 1; i <= Object.keys(unavailableNumbers).length+1; i++) {
    if (!unavailableNumbers[i]) {
      return i
    }
  }
}

export default getNextAvailableShirt
