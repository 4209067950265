import randomNo from "../functions/randomNo"
const tactic = ["Defensive", "Possession", "Attacking", "Counter-Attacking"]

const teams = [
  {
    shortName: "Twins",
    logo: "diamond",
    colors: [
      { primary: "194, 45%, 79%" },
      { secondary: "30, 93%, 66%" },
      { away: "155, 32%, 45%" },
      { other: "0, 78%, 89%" },
    ],
    stadium: {
      name: "Brotherly Street",
      capacity: 49000,
    },
    name: "Terrazzo Twins",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Cosmos",
    logo: "diamond",
    colors: [
      { primary: "237, 73%, 35%" },
      { secondary: "0, 0%, 100%" },
      { away: "360, 100%, 49%" },
      { other: "202, 100%, 52%" },
    ],
    stadium: {
      name: "Higher Center",
      capacity: 35000,
    },
    name: "First Cosmonauts",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Giants",
    logo: "diamond",
    colors: [
      { primary: "203, 7%, 53%" },
      { secondary: "201, 32%, 88%" },
      { away: "0, 0%, 100%" },
      { other: "0, 0%, 33%" },
    ],
    stadium: {
      name: "The Mountaintop",
      capacity: 95605,
    },
    name: "Stone Giants",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Loco",
    logo: "diamond",
    colors: [
      { primary: "11, 48%, 35%" },
      { secondary: "11, 3%, 17%" },
      { away: "40, 100%, 17%" },
      { other: "40, 33%, 67%" },
    ],
    stadium: {
      name: "Station Road",
      capacity: 54011,
    },
    name: "Locomotive",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Eagle",
    logo: "diamond",
    colors: [
      { primary: "43, 100%, 38%" },
      { secondary: "0, 0%, 100%" },
      { away: "11, 98%, 40%" },
      { other: "0, 0%, 100%" },
    ],
    stadium: {
      name: "The Collosus",
      capacity: 60400,
    },
    name: "The Eagle",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Dragons",
    logo: "diamond",
    colors: [
      { primary: "0, 100%, 48%" },
      { secondary: "0, 0%, 0%" },
      { away: "28, 100%, 50%" },
      { other: "51, 100%, 63%" },
    ],
    stadium: {
      name: "Father's Pride",
      capacity: 55000,
    },
    name: "Red Dragons",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Tortugas",
    logo: "diamond",
    colors: [
      { primary: "229, 37%, 40%" },
      { secondary: "40, 97%, 56%" },
      { away: "180, 100%, 50%" },
      { other: "203, 100%, 50%" },
    ],
    stadium: {
      name: "Concha de Tortuga",
      capacity: 40052,
    },
    name: "Las Tortugas",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "de Lys",
    logo: "diamond",
    colors: [
      { primary: "236, 63%, 37%" },
      { secondary: "57, 90%, 59%" },
      { away: "0, 0%, 100%" },
      { other: "0, 92%, 47%" },
    ],
    stadium: {
      name: "Le Parc",
      capacity: 41600,
    },
    name: "Fleur de Lys",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Union",
    logo: "concentric",
    colors: [
      { primary: "55, 100%, 49%" },
      { secondary: "0, 0%, 0%" },
      { away: "236, 71%, 55%" },
      { other: "0, 0%, 83%" },
    ],
    stadium: {
      name: "The Free Press",
      capacity: 26800,
    },
    name: "The Union",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Saints",
    logo: "diamond",
    colors: [
      { primary: "0, 0%, 100%" },
      { secondary: "0, 95%, 53%" },
      { away: "194, 95%, 49%" },
      { other: "0, 0%, 100%" },
    ],
    stadium: {
      name: "Church Street",
      capacity: 32600,
    },
    name: "Merry Saints",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Irons",
    logo: "diamond",
    colors: [
      { primary: "208, 45%, 32%" },
      { secondary: "54, 98%, 51%" },
      { away: "0, 0%, 100%" },
      { other: "0, 63%, 49%" },
    ],
    stadium: {
      name: "Castle Street",
      capacity: 57600,
    },
    name: "Ironmen",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Shamrock",
    logo: "diamond",
    colors: [
      { primary: "150, 95%, 31%" },
      { secondary: "0, 0%, 100%" },
      { away: "18, 95%, 49%" },
      { other: "0, 0%, 100%" },
    ],
    stadium: {
      name: "True Meadow",
      capacity: 21400,
    },
    name: "White Shamrock",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Lions",
    logo: "concentric",
    colors: [
      { primary: "50, 98%, 49%" },
      { secondary: "8, 61%, 49%" },
      { away: "0, 0%, 100%" },
      { other: "191, 92%, 72%" },
    ],
    stadium: {
      name: "Middle Kindgom",
      capacity: 33700,
    },
    name: "Aker Lions",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Palms",
    logo: "shield",
    colors: [
      { primary: "0, 86%, 84%" },
      { secondary: "108, 91%, 22%" },
      { away: "206, 70%, 73%" },
      { other: "58, 98%, 51%" },
    ],
    stadium: {
      name: "Botanical Gardens",
      capacity: 32300,
    },
    name: "Palmettes",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Lotus",
    logo: "diamond",
    colors: [
      { primary: "338, 98%, 49%" },
      { secondary: "342, 81%, 88%" },
      { away: "236, 43%, 37%" },
      { other: "161, 95%, 75%" },
    ],
    stadium: {
      name: "Westow Park",
      capacity: 25400,
    },
    name: "Sacred Lotus",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Bears",
    logo: "diamond",
    colors: [
      { primary: "56, 92%, 49%" },
      { secondary: "160, 69%, 37%" },
      { away: "194, 100%, 49%" },
      { other: "7, 95%, 50%" },
    ],
    stadium: {
      name: "Boothferry Road",
      capacity: 25400,
    },
    name: "Atlas Bears",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Hornets",
    logo: "diamond",
    colors: [
      { primary: "57, 100%, 52%" },
      { secondary: "0, 0%, 0%" },
      { away: "160, 95%, 35%" },
      { other: "99, 89%, 61%" },
    ],
    stadium: {
      name: "Golden Hart Road",
      capacity: 21400,
    },
    name: "Metropolitan Hornets",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Motors",
    logo: "diamond",
    colors: [
      { primary: "0, 0%, 0%" },
      { secondary: "0, 0%, 100%" },
      { away: "260, 61%, 71%" },
      { other: "259, 75%, 38%" },
    ],
    stadium: {
      name: "The Sound",
      capacity: 27900,
    },
    name: "Motor Town",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Empress",
    logo: "diamond",
    colors: [
      { primary: "128, 95%, 22%" },
      { secondary: "40, 98%, 50%" },
      { away: "7, 100%, 60%" },
      { other: "219, 50%, 43%" },
    ],
    stadium: {
      name: "The Parade",
      capacity: 21000,
    },
    name: "Old Empress",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Park",
    logo: "diamond",
    colors: [
      { primary: "240, 95%, 50%" },
      { secondary: "0, 0%, 100%" },
      { away: "31, 95%, 50%" },
      { other: "102, 70%, 40%" },
    ],
    stadium: {
      name: "Northern Park",
      capacity: 11400,
    },
    name: "Northern Park",
    continent: "Brittanic",
    tier: 1,
  },
  {
    shortName: "Racing",
    logo: "diamond",
    colors: [
      { primary: "4, 95%, 43%" },
      { secondary: "0, 0%, 100%" },
      { away: "0, 0%, 0%" },
      { other: "27, 95%, 50%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Fortuna Racing",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Hombres",
    logo: "diamond",
    colors: [
      { primary: "218, 96%, 36%" },
      { secondary: "180, 96%, 38%" },
      { away: "231, 81%, 87%" },
      { other: "0, 0%, 100%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Hombres de Hielo",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Fortezza",
    logo: "diamond",
    colors: [
      { primary: "174, 95%, 23%" },
      { secondary: "37, 95%, 52%" },
      { away: "196, 96%, 69%" },
      { other: "234, 97%, 60%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Fortezza di Lucertola",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Chevaliers",
    logo: "diamond",
    colors: [
      { primary: "211, 60%, 52%" },
      { secondary: "0, 94%, 60%" },
      { away: "0, 0%, 100%" },
      { other: "102, 42%, 39%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Les Chevaliers",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Jackdaws",
    logo: "diamond",
    colors: [
      { primary: "0, 0%, 0%" },
      { secondary: "264, 12%, 58%" },
      { away: "123, 63%, 88%" },
      { other: "65, 95%, 32%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "The Jackdaws",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Atomic",
    logo: "diamond",
    colors: [
      { primary: "51, 98%, 50%" },
      { secondary: "180, 74%, 49%" },
      { away: "317, 90%, 57%" },
      { other: "230, 72%, 49%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Atomic Kingfisher",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Pearls",
    logo: "diamond",
    colors: [
      { primary: "191, 64%, 68%" },
      { secondary: "4, 100%, 51%" },
      { away: "60, 98%, 49%" },
      { other: "215, 98%, 49%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "The Pearls",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Aztecs",
    logo: "diamond",
    colors: [
      { primary: "0, 100%, 72%" },
      { secondary: "11, 73%, 36%" },
      { away: "43, 100%, 61%" },
      { other: "157, 73%, 60%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Forgotten Aztecs",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Comets",
    logo: "diamond",
    colors: [
      { primary: "348, 59%, 33%" },
      { secondary: "36, 71%, 48%" },
      { away: "215, 36%, 59%" },
      { other: "0, 0%, 100%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Fallen Comets",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Vipers",
    logo: "diamond",
    colors: [
      { primary: "103, 73%, 15%" },
      { secondary: "53, 93%, 53%" },
      { away: "21, 93%, 53%" },
      { other: "0, 0%, 100%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Queen Vipers",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Riders",
    logo: "diamond",
    colors: [
      { primary: "0, 0%, 0%" },
      { secondary: "0, 0%, 58%" },
      { away: "178, 96%, 27%" },
      { other: "177, 16%, 76%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Dark Riders",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Major",
    logo: "diamond",
    colors: [
      { primary: "233, 67%, 25%" },
      { secondary: "48, 100%, 50%" },
      { away: "284, 80%, 29%" },
      { other: "311, 100%, 45%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Ursa Major",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Angels",
    logo: "diamond",
    colors: [
      { primary: "6, 100%, 45%" },
      { secondary: "0, 0%, 100%" },
      { away: "146, 92%, 21%" },
      { other: "49, 95%, 50%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Spartan Angels",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Panthers",
    logo: "diamond",
    colors: [
      { primary: "0, 0%, 0%" },
      { secondary: "43, 97%, 45%" },
      { away: "168, 96%, 45%" },
      { other: "200, 100%, 28%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Endless Panthers",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Magpies",
    logo: "diamond",
    colors: [
      { primary: "0, 0%, 0%" },
      { secondary: "249, 32%, 25%" },
      { away: "224, 78%, 76%" },
      { other: "55, 87%, 56%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Seven Magpies",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Jets",
    logo: "diamond",
    colors: [
      { primary: "240, 46%, 49%" },
      { secondary: "0, 0%, 100%" },
      { away: "344, 98%, 49%" },
      { other: "225, 69%, 73%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Jumbo Jets",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Roses",
    logo: "diamond",
    colors: [
      { primary: "343, 100%, 74%" },
      { secondary: "336, 88%, 35%" },
      { away: "234, 19%, 79%" },
      { other: "236, 32%, 20%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Lunar Roses",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Bobolinks",
    logo: "diamond",
    colors: [
      { primary: "48, 93%, 65%" },
      { secondary: "0, 0%, 0%" },
      { away: "256, 49%, 62%" },
      { other: "0, 0%, 100%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Sporting Bobolink",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Indigos",
    logo: "diamond",
    colors: [
      { primary: "231, 42%, 29%" },
      { secondary: "217, 46%, 41%" },
      { away: "0, 0%, 100%" },
      { other: "208, 21%, 82%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "Indigo Midnight",
    continent: "Brittanic",
    tier: 2,
  },
  {
    shortName: "Storm",
    logo: "diamond",
    colors: [
      { primary: "37, 95%, 50%" },
      { secondary: "275, 48%, 45%" },
      { away: "204, 97%, 64%" },
      { other: "218, 98%, 50%" },
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000,
    },
    name: "November Storm",
    continent: "Brittanic",
    tier: 2,
  },
]

teams.map((team, i) => {
  team.id = i
  team.stature = Math.round(team.stadium.capacity / 1000)
  team.formation = "4-4-2"
  team.tactic = tactic[randomNo(3)]
  team.unbeatenRun = 0
  team.squadOrder = new Array(20).fill().map((e, j) => {
    return (i + 1) * 20 + j
  })
  team.bankBalance = team.stadium.capacity * 19 // 19 home games per year
  team.ready = false
  return team
})

export default teams
