const whichTeam = (homeId, awayId, thisTeamId, opponent = false) => {
  let returnId
  if (!opponent) {
    returnId = homeId === thisTeamId ? homeId : awayId
  } else {
    returnId = homeId === thisTeamId ? awayId : homeId
  }
  return returnId
}
export default whichTeam
