import getCssClassName from "../../functions/getCssClassName"

import { useSelector, useDispatch } from "react-redux"
import workerSend from "../../appWorker"
import { getNextFixtureForTeam } from "../../functions/fixtures"
import { getTeamLeagueId } from "../../functions/teams"
import {
  changeFormationLocal,
  changeTacticLocal,
  swapPlayerPositionLocal,
} from "../../actions"
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd"

export default function FormationSelection() {
  const teams = useSelector((state) => state.teams)
  const players = useSelector((state) => state.players)
  const user = useSelector((state) => state.user)
  const team = useSelector((state) => state.teams[user.teamId])
  const seasons = useSelector((state) => state.seasons)
  const fixtures = useSelector((state) => state.fixtures)
  const dispatch = useDispatch()

  const formation = team.formation

  const nextMatch = getNextFixtureForTeam(
    fixtures.leagues[getTeamLeagueId(seasons, user.teamId)],
    fixtures.cups,
    user.teamId
  )

  let opponentId

  if (nextMatch) {
    opponentId =
      nextMatch.homeId === user.teamId ? nextMatch.awayId : nextMatch.homeId
  }

  const changeFormation = (formation) => {
    if (user.isLocalGame) {
      dispatch(changeFormationLocal(user.teamId, formation))
    } else {
      workerSend("changeFormation", { formation })
    }
  }

  const changeTactic = (tactic) => {
    if (user.isLocalGame) {
      dispatch(changeTacticLocal(user.teamId, tactic))
    } else {
      workerSend("changeTactic", { tactic })
    }
  }

  const getTeamRatings = (team) => {
    const showPlayerRatingFor = (criteria, label) => {
      return (
        <li>
          {label}
          <br />
          <div
            className="stars"
            style={{ "--rating": criteria / 20 }}
            aria-label={label}
          ></div>
        </li>
      )
    }
    let physical = 0,
      mental = 0,
      technical = 0,
      overall = 0
    team.squadOrder.slice(0, 11).forEach((playerId) => {
      physical += players[playerId].rating.physical
      mental += players[playerId].rating.mental
      technical += players[playerId].rating.technical
    })
    overall += physical + mental + technical
    return (
      <ul>
        {showPlayerRatingFor(Math.round(physical / 11), "Physical")}
        {showPlayerRatingFor(Math.round(mental / 11), "Mental")}
        {showPlayerRatingFor(Math.round(technical / 11), "Technical")}
        {showPlayerRatingFor(Math.round(overall / 11 / 3), "Overall")}
      </ul>
    )
  }

  const swapPlayerPosition = (currentIndex, newIndex) => {
    if (user.isLocalGame) {
      dispatch(swapPlayerPositionLocal(team.id, currentIndex, newIndex))
    } else {
      workerSend("swapPlayerPosition", { currentIndex, newIndex })
    }
  }

  const onDragEnd = ({ source, destination }) => {
    // droppableId in source and destination
    // if needed for having more than one thing in the context
    // also, draggableId if needed from result (same level as source and destination)
    if (
      !destination ||
      (destination.index === source.index &&
        destination.droppableId === source.droppableId)
    ) {
      return null
    }

    swapPlayerPosition(source.index, destination.index)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <section className="formation-select">
        <div className="grid grid-cols-2">
          <section>
            <div className="grid grid-cols-2">
              <ul>
                <li
                  className={getCssClassName(team.shortName) + "-" + team.logo}
                >
                  {team.shortName}
                </li>
                {getTeamRatings(team)}
              </ul>
              {opponentId && (
                <ul>
                  <li
                    className={
                      getCssClassName(teams[opponentId].shortName) +
                      "-" +
                      teams[opponentId].logo
                    }
                  >
                    {teams[opponentId].shortName}
                  </li>
                  {getTeamRatings(teams[opponentId])}
                </ul>
              )}
            </div>
            <hr className="my-4" />
            <ul className="flex items-center gap-6">
              <li>
                <ul className="flex items-center gap-2">
                  <li className="text-sm">Formation</li>
                  <li>
                    <select
                      className="border border-black px-1"
                      value={team.formation}
                      onChange={(e) => {
                        changeFormation(e.target.value)
                      }}
                    >
                      <option value="4-4-2">4-4-2</option>
                      <option value="4-5-1">4-5-1</option>
                      <option value="4-3-3">4-3-3</option>
                      <option value="3-4-3">3-4-3</option>
                      <option value="3-5-2">3-5-2</option>
                    </select>
                  </li>
                </ul>
              </li>
              <li>
                <ul className="flex items-center gap-2">
                  <li>Tactic</li>
                  <li>
                    <select
                      className="border border-black px-1"
                      value={team.tactic}
                      onChange={(e) => {
                        changeTactic(e.target.value)
                      }}
                    >
                      <option value="Defensive">Defensive</option>
                      <option value="Possession">Possession</option>
                      <option value="Attacking">Attacking</option>
                      <option value="Counter-attacking">
                        Counter-Attacking
                      </option>
                    </select>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          <div className={"formation--" + formation + " bg-green-500"}>
            {team.squadOrder.slice(0, 11).map((playerId, i) => {
              const player = players[playerId]
              const shirtNumber =
                player.borrowingTeamId === null
                  ? player.shirtNumber
                  : player.loanShirtNumber
              return (
                <Droppable
                  droppableId={"formationPositionId" + i}
                  key={"formationPosition" + i}
                >
                  {(provided) => (
                    <div
                      className={"dot-" + (i + 1)}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <Draggable
                        draggableId={"draggableFormationPositionId" + i}
                        index={i}
                        key={"draggableFormationPosition" + i}
                      >
                        {(provided) => (
                          <span
                            className={
                              "flex justify-center items-center flex-col"
                            }
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <span
                              className={
                                getCssClassName(team.shortName) +
                                "--primary dot-" +
                                (i + 1) +
                                " flex justify-center items-center h-8 w-8 transition-all text-white rounded-full z-20 leading-none"
                              }
                            >
                              <span>{shirtNumber}</span>
                            </span>
                            <span
                              className={
                                "dot-" +
                                (i + 1) +
                                " z-20 uppercase font-black text-sm"
                              }
                            >
                              {player.lastName}
                            </span>
                          </span>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )
            })}
            <div className="six-yard"></div>
            <div className="penalty-area bg-green-500"></div>
            <div className="penalty-arc"></div>
            <div className="center-circle"></div>
          </div>
        </div>
      </section>
    </DragDropContext>
  )
}
