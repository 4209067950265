export const serverConnecting = () => ({ type: 'serverConnecting' })
export const serverUpdate = (json) => ({ ...json })
export const leaveGameLocal = () => ({ type: 'leaveGame' })
export const gameNotFound = () => ({ type: 'gameNotFound' })
export const newGame = (userId, managerName) => ({ type: 'newGame', userId, managerName })
export const playersTraining = (teams) => ({ type: 'playersTraining', teams })
export const redirectedForMatchPlayed = () => ({ type: 'redirectedForMatchPlayed' })

export const movePlayerPositionLocal = (teamId, currentIndex, newIndex) => {
  return {
    type: 'movePlayerPosition',
    teamId,
    currentIndex,
    newIndex
  };
};

export const swapPlayerPositionLocal = (teamId, currentIndex, newIndex) => {
  return {
    type: 'swapPlayerPosition',
    teamId,
    currentIndex,
    newIndex
  };
};

export const releasePlayer = (player, teamId, fee) => {
  return {
    type: 'releasePlayer',
    player: player,
    teamId: teamId,
    fee: fee
  };
};

export const extendPlayer = (player, years) => {
  return {
    type: 'extendPlayer',
    player: player,
    years: years
  }
}

export const signFreeAgent = (player, teamId) => {
  return {
    type: 'signFreeAgent',
    player: player,
    teamId: teamId
  };
};

export const matchPlayed = result => {
  return {
    type: 'matchPlayed',
    result,
  }
}

export const clubSelected = (userId, teamId) => {
  return {
    type: 'clubSelected',
    userId,
    teamId
  }
}

export const nextSeasons = (teams) => {
  return {
    type: 'nextSeasons',
    teams,
  }
}

export const nextYear = (gameYears, cups, nextCupYear) => {
  return {
    type: 'nextYear',
    gameYears,
    cups,
    nextCupYear
  }
}

export const addLeagueFixtures = (fixtures) => {
  return {
    type: 'addLeagueFixtures',
    fixtures
  }
}

export const addCupFixtures = (fixtures) => {
  return {
    type: 'addCupFixtures',
    fixtures
  }
}

export const adjustBankBalance = (teamId, change) => {
  return {
    type: 'adjustBankBalance',
    teamId: teamId,
    change: change
  }
}

export const bidForPlayer = (bid) => {
  return {
    type: 'bidForPlayer',
    bid
  }
}

export const loanBidForPlayer = (playerId, buyingTeamId, sellingTeamId, dateTime) => {
  return {
    type: 'loanBidForPlayer',
    playerId: playerId,
    buyingTeamId: buyingTeamId,
    sellingTeamId: sellingTeamId,
    dateTime: dateTime
  }
}

export const endPlayerLoan = (playerId, borrowingTeamId, owningTeamId) => {
  return {
    type: 'endPlayerLoan',
    playerId,
    borrowingTeamId,
    owningTeamId,
  }
}

export const bidAccepted = (bid) => {
  return {
    type: 'bidAccepted',
    bid: bid
  }
}

export const bidRejected = (bid) => {
  return {
    type: 'bidRejected',
    bid: bid
  }
}

export const progressToDate = (newDate) => {
  return {
    type: 'progressToDate',
    newDate
  }
}
export const togglePlayerTransferStatus = (player) => {
  return {
    type: 'togglePlayerTransferStatus',
    player: player
  }
}
export const togglePlayerLoanStatus = (player) => {
  return {
    type: 'togglePlayerLoanStatus',
    player: player
  }
}

export const addNews = (news) => {
  return {
    type: 'addNews',
    news
  }
}

export const addMilestone = (leagueId, cupId, teamId, milestone) => {
  return {
    type: 'addMilestone',
    leagueId: leagueId,
    cupId: cupId,
    teamId: teamId,
    milestone: milestone
  }
}

export const changeFormationLocal = (teamId, formation) => {
  return {
    type: 'changeFormation',
    teamId: teamId,
    formation: formation
  }
}

export const changeTacticLocal = (teamId, tactic) => {
  return {
    type: 'changeTactic',
    teamId: teamId,
    tactic: tactic
  }
}

export const updatePlayerHistory = (playerId, playerHistory) => {
  return {
    type: 'updatePlayerHistory',
    playerId,
    playerHistory
  }
}

export const addPlayerToLongList = (playerId) => {
  return {
    type: 'addPlayerToLongList',
    playerId: playerId,
  }
}

export const removePlayerFromLongList = (playerId) => {
  return {
    type: 'removePlayerFromLongList',
    playerId: playerId,
  }
}

export const addTeamToCupYear = (cupId, teamId, year) =>
  ({ type: 'addTeamToCupYear', cupId, teamId, year })

export const relegateTeam = (teamId) =>
  ({ type: 'relegateTeam', teamId })

export const promoteTeam = (teamId) =>
  ({ type: 'promoteTeam', teamId })

export const archiveNewsItem = (newsItemId) => ({ type: 'archiveNewsItem', newsItemId })

export const archiveAllNewsItems = () => ({ type: 'archiveAllNewsItems' })

export const promotePlayerToSeniorSquad = (teamId, playerId) =>
  ({ type: 'promotePlayerToSeniorSquad', teamId: teamId, playerId: playerId })

export const demotePlayerToYouthSquad = (teamId, playerId) =>
  ({ type: 'demotePlayerToYouthSquad', teamId: teamId, playerId: playerId })

export const teamReady = (teamId) => ({ type: 'teamReady', teamId })

export const unreadyTeams = (teamId) => ({ type: 'unreadyTeams' })

export const testHelperExaggerateForm = (player) => ({ type: 'testHelperExaggerateForm', player })
