import marketValue from '../functions/marketValue';
import { newBidNews } from "../functions/market"
import getNextAvailableShirt from "../functions/getNextAvailableShirt"
import { getDateWithAddedDays } from "../functions/generateLeagueFixtures"

const aiBids = (seasonCount, teams, players, currentTime, users) => {
  const news = []
  const newBids = []

  for (let i = 0; i < 3; i++) {
    const randomNo = limit => Math.floor(Math.random() * limit)
    const allTeams = [...teams]
    const aiTeams = teams.filter(team => !team.userId)

    const randomBuyer = aiTeams[randomNo(aiTeams.length)]
    allTeams.splice(randomBuyer.id, 1)

    const randomSeller = allTeams[randomNo(allTeams.length)]
    const randomPlayerId = randomSeller.squadOrder[randomNo(randomSeller.squadOrder.length)]
    const player = players[randomPlayerId]

    newBidNews(news, teams, users, randomSeller, currentTime, randomBuyer, player)

    newBids.push({
      playerId: randomPlayerId,
      buyingTeamId: randomBuyer.id,
      sellingTeamId: randomSeller.id,
      fee: Math.round(marketValue(player, seasonCount)),
      dateTime: currentTime,
      type: "transfer",
      status: "",
      expires: getDateWithAddedDays(currentTime, 5).toISOString()
    })
  }

  return { news, newBids }
}

const processBids = (bids, teams, players, seasonCount, currentTime) => {
  // If we have any bids
  // Process each bid and either complete the Transfer
  // Or reject the bid

  let bidsToAccept = []
  let bidsToReject = []

  // For now this is just based on luck. Higher bids for the same player make no difference.
  if (bids.length > 0) {
    let acceptedPlayerIds = []
    let teamSquadCounts = []
    let newShirtNumbers = []
    teams.forEach(team => {
      teamSquadCounts.push(team.squadOrder.length)
      newShirtNumbers[team.id] = []
    })

    bids.forEach(bid => {
      if (bid.status === "" && !teams[bid.sellingTeamId].userId) {
        const luck = Math.round(Math.random())
        if (
          luck
          && !acceptedPlayerIds.includes(bid.playerId)
          && teamSquadCounts[bid.sellingTeamId] > 11
          && bid.fee >= marketValue(players[bid.playerId], seasonCount)
        ){
            bid.newShirtNumber = getNextAvailableShirt(players, teams[bid.buyingTeamId].squadOrder, newShirtNumbers[bid.buyingTeamId])
            newShirtNumbers[bid.buyingTeamId].push(bid.newShirtNumber)
            bidsToAccept.push(bid)
            acceptedPlayerIds.push(bid.playerId)
            teamSquadCounts[bid.sellingTeamId]--
            teamSquadCounts[bid.buyingTeamId]++
        } else {
            bidsToReject.push(bid)
        }
      } else if (
          !bid.status
          && teams[bid.sellingTeamId].userId
          && currentTime > bid.expires
      ) {
        bidsToReject.push(bid)
      }
    })
  }
  return { bidsToAccept, bidsToReject }
}

export {
  processBids,
  aiBids
}
