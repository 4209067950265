const getTeamCircle = (hsl) => (
  <span
    className="rounded-full"
    style={{
      backgroundColor: `hsl(${hsl})`,
      display: "block",
      height: "1rem",
      width: "1rem",
      margin: "-2px",
    }}
  ></span>
)
export default getTeamCircle
